<template>
  <v-ons-page>
    <div class='headerSec'>

      <div id="fagfilm-header">
        <router-link :to="{ name: 'Authenticator' }">
          <v-ons-button type="info" class="auth-button" v-if="!user">
            Logg inn
          </v-ons-button>
        </router-link>

        <!-- <router-link :to="{ name: 'CreateAccount' }">
          <v-ons-button class="create-account-button" v-if="!user">
            Påmelding
          </v-ons-button>
        </router-link> -->

        <amplify-sign-out class="auth-button" v-if="user"></amplify-sign-out>
      </div>
      
      <!-- <div id="messageboard"><Plakat /></div> -->

      <div class="cover-content-wrapper">
        <div class="kom-i-gang-boks" :class="{'standalone-kom-i-gang': !isBrowser}">
          
            <div class="kom-i-gang-tekst">
              <h1 class="hva-er-fagfilm">Utdanningsvalg på film</h1>
              <p class="oppfordring">OPPLEV OG UTFORSK UTDANNINGS- OG YRKESMULIGHETER</p>
            </div>
            
            <div class="kom-i-gang-link-wrap">
              <div id="center-box">
                <router-link :to="{ name: 'order' }">
                  <v-ons-button class="green_bg get-started-button" v-if="!user">
                      BESTILL FAGFILM
                  </v-ons-button>
                </router-link>

                <router-link :to="{ name: 'CreateAccount' }">
                  <v-ons-button class="create-account-button get-started-button" v-if="!user">
                    BLI BRUKER
                  </v-ons-button>
                </router-link>

                <router-link :to="{ name: 'library' }">
                  <v-ons-button class="red_bg get-started-button">
                    SE FILM
                  </v-ons-button>
                </router-link>
                <router-link :to="{ name: 'privacy' }">
                  <v-ons-button class="gray_bg get-started-button">
                    PERSONVERN
                  </v-ons-button>
                </router-link>                
              </div>
            </div>

            <div id="support-plakat">
                <p><span id="suppport-text">Support: Send sms eller ring 915 68 963</span> <span id="nerd">🤓</span></p>
            </div>
        </div>
        
      </div>
        
    </div>
    
  </v-ons-page>
</template>

<script>

// import Plakat from './plakat'
export default {
  data () {
    return {
      isBrowser: true
    }
  },
  components: {

  },
  mounted () {
    if (window.navigator.standalone == true) {
    // console.log('STANDALONE')
      this.isBrowser = false
      // make all link remain in web app mode.
      // $('a').click(function () {
      //   window.location = $(this).attr('href')
      //   return false
      // })
    }
  },
  methods: {
    showModal () {
      this.modalVisible = true
    }
  },
  computed: {
    user: function () {
      return this.$store.getters.user
    },
    authenticated () {
      return this.$store.getters.authenticated
    }
  }
}
</script>

<style lang="scss" scoped>

.modal-menu {
  max-width: 600px
}

// Front top
img {
  max-width: none !important;
}

#front-page {
  font-family: 'Helvetica Neue', Helvetica, Arial,sans-serif !important;
  background: #141414;
  -webkit-font-smoothing: antialiased;
  color: white;
}
#messageboard {
  width: 100%;
  position: absolute;
  z-index: 100;
  display: grid;
  margin-top: 30vh;
  @media screen and (max-height: 650px) {
    margin-top: 10vh;
  }

  @media screen and (max-height: 450px) {
    margin-top: 5px;
  }
}

#support-plakat {
  #suppport-text {
    font-style: italic;
    font-weight: bold;
  }
  #nerd {
      position:relative;
      top: 4px;
      left:8px;
      font-size: 1.8em
  }

}
#fagfilm-header {
  height: 50px;
  border: 0;
  position: absolute;
  width: 100%;
  z-index: 100;
  display: block;
}

.auth-button {
  display: inline-block;
  position: absolute;
  top: 60px;
  right: 20px;
  z-index: 100;
  @media screen and (min-width: 768px) {
    margin-top: 0px;
    margin: 26px 3% 0;
  }
}
.create-account-button {
  background-color: rgb(0, 140, 255);
}
.get-started-button {
  width: 166px;
}

@media screen and (min-width: 768px) {
  // .fagfilm-header {
  //   height: 90px;
  // }
}

.headerSec {
  background-image: url('./large.jpg');
  background-position: 12% 0%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}

/* Front header */
/* scoll colors */

.cover-content-wrapper {
  width:100%;
  height:100%;
  background: linear-gradient(180deg, rgba(0,0,0,.7) , rgba(0,0,0,.6), rgba(0,0,0,0) 20% , rgba(0,0,0,0) 55%,  rgba(0,0,0,.5), rgba(0,0,0,.3) );
  /* background: linear-gradient(0deg, rgba(255,0,0,0) 60%, rgba(0,255,0,0.09) 50%, rgba(0,0,255,0.7) 97%, rgba(0,255,255,0.8) 100%); */
  @media (min-width: 1000px) {
    /* background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.56) 65%, rgba(0,0,0,0.7) 100%); */
  }
}

/* background gradient */

// Kom i gang boks
.standalone-kom-i-gang {
  padding-bottom: 36px !important;
}

.kom-i-gang-boks {
  position: absolute;
  bottom: 36px;
  margin:0;
  
  text-align: center;
  width: 100%;
  transform: none;
  padding-top: 60px;
  padding-bottom: 90px;
  // background: rgba(200,0,0,0.5);
  background: linear-gradient(to top, rgba(0,0,0,.7) 0%, rgba(0,0,0,.6) 50%, rgba(0,0,0,0) 100%);

  .kom-i-gang-link-wrap {
    opacity: 1;
  }
  #center-box {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto auto auto;
    grid-gap: 6px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 548px) {
      padding: 10px;
      max-width: 746px;
      // grid-gap: px;
      grid-template-columns: auto auto auto auto;
    }    
  }
  .kom-i-gang-tekst {
    -webkit-font-smoothing: antialiased;
    font-size: 1.8vw;
    position:relative;
    h1 {
      margin-bottom: 12px;
    }
    top: 0;
    p {
      font-size: 1.6em;
      margin-bottom: 24px;
    }
  }

  @media (min-width: 658px) {
    .oppfordring {
      font-size: 1em;
    }
  }
}
</style>
