<template lang="html">
  <router-link
  v-if="video"
    class="link-wrapper"
    tag="div"
    :to="{ name: 'player', params: { categorySlug: category.slug, videoSlug: video.slug, chapter: 'kapittel-0:start' } }"
  >
    <div class="video-link">

      <div class="video-title-area-right">
        <div class="video-title">{{video.title}}</div>
      </div>

      <img :src="thumb (video.fileName, video.posterTime, this.$root.Constants.THUMBNAIL_WIDTH)">

      <div class="video-title-area-bottom">
        <div class="video-title">{{video.title}}</div>
      </div>

    </div>

  </router-link>

 
</template>

<script>
import { thumb, secondsToMMSS } from '@/utils'

export default {
  props: ['video', 'category'],
  methods: {
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },
    formatTime (start) {
      return secondsToMMSS(start)
    }
  },
  computed: {
    // videoSlug () {
    //   if(this.video) {
    //     return video.slug
    //   } else {
    //     return "slug-undefined"
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.video-link {
  background-color: $dark-text-bg-color;
  border-bottom: 1px solid #222222;
  margin-bottom: 1.7vw;
  @include mquery(very_large_mobile) {
    margin-bottom: unset;
  }
  img {
    display: block;
    width: 45vw;
    height: 20vw;
    object-fit: cover;
    @include mquery(very_large_mobile) {
      object-fit: fill;
      width: 100%;
      height: auto;
    }
  }
  .video-title-area-right {
    height: 20vw;
    position: absolute;
    left: 45vw;
    display: flex;
    justify-content: center;
    @include mquery(very_large_mobile) {
      display:none
    }
  }
  .video-title-area-bottom {
    display: none !important;
    justify-content: center;
    height: 48px;
    @include mquery(very_large_mobile) {
      display:block !important;
      position: static;
      display: flex !important;
    }
  }
  .video-title {
    align-self: center;
    padding-left: 3vw;
    padding-right: 3vw;
    font-size: 4.8vw;
    @include mquery(very_large_mobile) {
      font-size: 1em;
    }
  }
  .time {
    position: absolute;
    left: 6px;
    top: 5px;
    font-size: 0.7em;
    line-height: 16px;
    @include mquery(very_large_mobile) {

      left: auto;
      top: auto;
      right: 5px;
      bottom: 2px;
    }
  }
}
</style>
