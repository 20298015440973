<template lang="html">
  <div id="v-chapters">
    <template v-for="(chapter, key) in video.chapters.items">
      <ChapterLinkV :chapter="chapter" :chapters="chapters"
        :video="video" :chapterNumber="key + 1" :key="key"/>
    </template>
  </div>
</template>

<script>

import ChapterLinkV from './ChaptersLinksLink-vert'
export default {
  name: 'ChaptersBaseLinks',
  props: ['video'], // video does not contain chapters
  data () {
    return {
      isLoading: false,
      chapters: [],
    }
  },
  components: { ChapterLinkV }
}
</script>

<style lang="scss" scoped>

  #v-chapters {
    padding-top: .1em;
    padding-left: .1em;
    // background: orange;
    display: grid;
    align-content: start;
    grid-template-rows: auto;
    column-gap: .1em;
    row-gap: .1em;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;

    grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

    /* PORTRAIT LAYOUT */

    // @media only screen and (max-aspect-ratio: 200/100) and (min-width: 600px) { // iphone landscape
    //   grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
    // }
    // A littele wider for iPad landscape eception    
    
    @media only screen and (max-aspect-ratio: 170/100) { // 276/100) // 8px margin left and rigfht on video player
      grid-template-columns: repeat(auto-fill, minmax(55%, 1fr));
      @media (min-width: 300px) {
        grid-template-columns: repeat(auto-fill, minmax(45%, 1fr));
      }      
      @media (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
      }
      @media (min-width: 1025px) {
        grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
      }
    }


    /* LANDSCAPE LAYOUT */

    @media only screen and (min-aspect-ratio: 225/100) {
      grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
    }
    @media only screen and (min-aspect-ratio: 230/100) {
      grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
    }
    @media only screen and (min-aspect-ratio: 350/100) {
      grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
    }
    @media only screen and (min-aspect-ratio: 450/100) {
      grid-template-columns: repeat(auto-fill, minmax(24%, 1fr));
    }
    @media only screen and (min-aspect-ratio: 520/100) {
      grid-template-columns: repeat(auto-fill, minmax(19%, 1fr));
    }
    @media only screen and (min-aspect-ratio: 590/100) {
      grid-template-columns: repeat(auto-fill, minmax(15%, 1fr));
    }

  }


  .v-scroller {
    background: orange;
    display: block;
    display: grid;
    grid-template-rows: auto;
    column-gap: 1%;
    row-gap: 1%;
    align-content: start;
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    // &::-webkit-scrollbar {
    //   display: none;
    // }
  }
</style>
