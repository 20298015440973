<template>
  <v-ons-page>
    <MobileTopNav :showCatalog="true" :showHome="true"/>
    <FilmFront/>
  </v-ons-page>
</template>

<script>
import FilmFront from '@/components/film_front/FilmFront'
import MobileTopNav from '@/components/menus/MobileTopNav'

export default {
  components: {
    FilmFront,
    MobileTopNav
  },
  methods: {
    capitalize (s) {
      return s && s[0].toUpperCase() + s.slice(1)
    }
  }
}
</script>

<style lang="scss" scoped>
  // .chapter-player {
  //   display: grid;
  //   grid-template-rows: auto 1fr;
  //   height: 400px;
  // }
</style>
