<template>
  <div class="film-links" style="color: white">
    <div v-if="loaded">
      <div v-for="category in categories" :key="category.id" class="link-rows">
        <FilmFrontLinks :category="category" />
      </div>
    </div>
    <div v-else>
      <ImageLoader style="height: calc(100vh - 47px)"/>
    </div>
  </div>
</template>

<script>

import FilmFrontLinks from './FilmFrontLinks'
import { Cache } from 'aws-amplify' // , API, Auth 
import ImageLoader from './ImageLoader'
import { loadContent, loadImages } from '@/utils'
export default {
  name: 'Videos',
  data () {
    return {
      imagesLoaded: 0,
      categories: [],
      loaded: false
    }
  },
  async created () {
    const categories = Cache.getItem('categories')
    if (categories && categories.length > 0) {
      console.log('CATEGORIES LENGTH: ', categories.length)
      this.categories = categories
      this.loaded = true
    } else {
      const filteredCats = await loadContent()
      Cache.setItem('categories', filteredCats)
      this.categories = filteredCats
      // await loadImages()
      this.loaded = true
      console.log('load res:\n', filteredCats)
    }
  },
  components: {
    FilmFrontLinks,
    ImageLoader
  },
  computed: {
    allLoaded () {
      // console.log('allLoaded ', this.$store.state.allLoaded)
      return this.$store.state.allLoaded
    },
    loading () {
      return this.$store.state.loading
    }
  },
  methods: {

  }
}

</script>

<style lang="scss" scoped>
  .link-rows {
    // margin-top: 2.7rem;
    display: block;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    -ms-scroll-snap-type: mandatory;
  }
</style>