<template>
  <v-ons-page class="auth-page">
    <MobileTopNav :showCatalog="true" :showHome="true"/>
    <div class="content">
        <amplify-container>
          <amplify-authenticator 
          id="custom-authenticator" 
          :initial-auth-state="initialAuthState"
          >
            <amplify-toast slot="amplify-toast"></amplify-toast>

            <amplify-sign-up
              slot="sign-up"
              username-alias="email"
              :form-fields.prop="formFields"
            >
            </amplify-sign-up>

            <amplify-sign-in
              slot="sign-in"
              username-alias="email"
            >

            <div slot="federated-buttons" id="feide-login">
              <h3 style="color: #1E90FF">Hvis du ønsker å logge inn med Feide kan du gjøre det på våre nye sider <a href="https://ny.fagfilm.no">her</a></h3>
              <p style="color: #252525">Skolen/kommunen må ha aktivert Fagfilm-tjenesten i Feide</p>
              <p>Eller login med din Fagfilm-konto:</p>
            </div>

            
              <!-- Feide login-knapp -->

              <!-- <div slot="federated-buttons" id="feide-login">
                <img src="@/assets/logo-feide-111px.png" />
                <v-ons-button 
                  modifier="large" 
                  style="margin: 10px 0; background-color: #92B9ED; color: black;" 
                  @click="toFeide()">Logg inn med Feide
                </v-ons-button>
                <p>Eller med epost og passord:</p>
              </div> -->


            </amplify-sign-in>

            <div id="amplify-signout"><!--  v-if="authState === 'signedin' && user" -->
              <!--amplify-sign-out id="amplify-signout" button-text="Logg ut"></amplify-sign-out-->
              <v-ons-progress-circular indeterminate></v-ons-progress-circular>
            </div>
          </amplify-authenticator>
          <div v-if="confirming" class="auth-info">
            <p><i>Om et minutt eller fire får du en epost med bekreftelseskode.<br>
            NB: Noen ganger tar "bekreft" litt tid. Vær tålmodig.. </i>😇</p>
            <p>HUSK OGSÅ Å SJEKKE SØPPELPOST!</p>
          </div>

        </amplify-container>
        <div style="width: 100%">
          <Privacy />
        </div>
        
    </div>

  </v-ons-page>
</template>

<script>

import { I18n } from '@aws-amplify/core';
import { strings } from './norwegian';
I18n.putVocabularies(strings);
I18n.setLanguage('no_NB');
import Privacy from './privacy-login';
import MobileTopNav from '@/components/menus/MobileTopNav'
import {  onAuthUIStateChange } from '@aws-amplify/ui-components'
import uuid from 'uuid-random';
import { redirect_uri } from '@/constants'
// Translation:
// https://github.com/aws-amplify/amplify-js/issues/6898
// https://github.com/aws-amplify/amplify-js/pull/6325

// ************* testkoder: baseth, dylov, koprev

export default {
  name: 'App',
  methods: {
    async toFeide() {

      localStorage.feideHash = await this.getHashedState ()
      const endpoint = 'https://auth.dataporten.no/oauth/authorization'
      const clientId = '48c8275e-44e1-44e6-9252-080abf0b98ce'
      const feideUrl = `${endpoint}?client_id=${clientId}&response_type=code&redirect_uri=${redirect_uri}&scope=openid&state=${localStorage.feideHash}`
      // alert(feideUrl)
      console.log(feideUrl)
      window.location.href = feideUrl
    },
    async getHashedState () {
      const { createHmac } = await import('crypto');
      const secret = uuid()
      const openIdState = uuid()
      // console.log('secret', secret)
      const hashedState = createHmac('sha256', secret)
                    .update(openIdState)
                    .digest('hex');
      return hashedState // { secret, hashedState, openIdState }
    }
  },
  data () {
    return {
      session: undefined,
      secret: undefined,
      client: undefined,
      user: undefined,
      authState: true,
      initialAuthState: 'signin', // confirmSignUp signin
      checkSkolekode: false,
      creatingAccount: false,
      confirmSignUp: false,
      longTime: false,
      signingIn: false,
      username: '',
      password: '',
      skolekode: '',
      confirmCode: '', 
      signedIn: false,
      formFields: [
        {
          type: 'email',
          label: 'Epostadresse',
          placeholder: 'min.bruker@hjemme.no',
          required: true,
          handleInputChange: (event, cb) => {
            this.username = event.target.value
            cb(event);
          }
        },
        {
          type: 'password',
          label: 'Passord',
          placeholder: 'MinHemmelighet123',
          required: true,
          handleInputChange: (event, cb) => {
            this.password = event.target.value
            cb(event);
          }
        },
        {
          type: 'zoneinfo',
          label: 'Skolekode',
          placeholder: 'skolekode',
          required: true,
          handleInputChange: (event, cb) => {
            this.skolekode = event.target.value
            cb(event);
          }
        }
      ]
    }
  },
  async created() {
    // console.log('created', this.initialAuthState)
    if (this.$route.name === 'CreateAccount') {
      console.log('create account')
      this.initialAuthState = 'signup' // 'signup'
    }
  },
  beforeDestroy() {
    return onAuthUIStateChange;
  },
  computed: {
    confirming () {
      // console.log('comp this.authState', this.authState)
      return this.authState === 'confirmSignUp' ? true : false
    }
  },
  components: {
    MobileTopNav, Privacy
  }
}
</script>

<style lang="scss">
  .auth-info {
    // background-color: green;
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    // width: 100%;
  }

  #feide-login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      width: 90px;
      margin-top: 8px;
      margin-bottom: 22px;
    }
    p {
      color: gray;
      margin-top: 1.4em;
      margin-bottom: 1.6em;
    }
  }
  amplify-container {
    width: auto;
    height: auto;
    margin-left: auto;
    margin-right:auto;
    // min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  amplify-authenticator {
    margin-bottom: -15px; 
    padding-top: 3vw;
    // width: inherit;
    // margin-left: auto;
    // margin-right:auto;
    --container-height: auto;
  }  

  :root {
    --amplify-primary-color:#CC0000;
  }
</style>
