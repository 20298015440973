<template lang="html">
  <v-ons-toolbar-button>

    <v-ons-icon class="burger-button" @click="showModal()" v-if="!modalVisible" icon="fa-bars" style="color: white"></v-ons-icon>
    <v-ons-icon class="burger-button" @click="hideModal()" v-if="modalVisible" icon="window-close" style="color: white"></v-ons-icon>

    <v-ons-modal :visible="modalVisible"
      animation="fade" @click="modalVisible = false"
      class="modal-menu" v-bind:class="{ 'modal-menu-iPhoneX': $ons.platform.isIPhoneX() }">
      <v-ons-list class="nav-links">
        <v-ons-list-item modifier="chevron" tappable @click="go('Home')">Hjem</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('library')">Filmkatalog</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('order')">Bestill fagfilm</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('privacy')">Personvern</v-ons-list-item>
        <!-- <v-ons-list-item modifier="chevron" tappable @click="to('https://auth.dataporten.no/openid/endsession')">Logg ut fra Feide</v-ons-list-item> -->

        <!-- <v-ons-list-item modifier="chevron" tappable @click="go('AboutTheFilms')">Om filmene</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('ped')">Pedagogisk opplegg</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('about')">Om oss</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('contact')">Kontakt</v-ons-list-item> -->

        <v-ons-list-item tappable>
          <amplify-sign-out class="auth-button"></amplify-sign-out>
          
        </v-ons-list-item>

      </v-ons-list>
    </v-ons-modal>

  </v-ons-toolbar-button>
</template>

<script>
// <!-- v-bind:signOutConfig="signOutConfig" -->
/*
  <v-ons-toolbar-button>

    <v-ons-icon class="burger-button" @click="showModal()" v-if="!modalVisible" icon="fa-bars" style="color: white"></v-ons-icon>
    <v-ons-icon class="burger-button" @click="hideModal()" v-if="modalVisible" icon="window-close" style="color: white"></v-ons-icon>

    <v-ons-modal :visible="modalVisible"
      animation="fade" @click="modalVisible = false"
      class="modal-menu" v-bind:class="{ 'modal-menu-iPhoneX': $ons.platform.isIPhoneX() }">
      <v-ons-list class="nav-links">
        <v-ons-list-item modifier="chevron" tappable @click="go('home')">Hjem</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('library')">Filmkatalog</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('order')">Bestill fagfilm</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('AboutTheFilms')">Om filmene</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('ped')">Pedagogisk opplegg</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('about')">Om oss</v-ons-list-item>
        <v-ons-list-item modifier="chevron" tappable @click="go('contact')">Kontakt</v-ons-list-item>

        <v-ons-list-item tappable>
          <amplify-sign-out class="auth-button"></amplify-sign-out>
          
        </v-ons-list-item>

      </v-ons-list>
    </v-ons-modal>

  </v-ons-toolbar-button>
*/
export default {
  data () {
    return {
      modalVisible: false
    }
  },
  methods: {
    go (to) {
      this.$router.push({ name: to })
    },
    to (loc) {
      this.$router.go(loc)
    },
    showModal () {
      this.modalVisible = true
    },
    hideModal () {
      this.modalVisible = false
    },
    signOutConfig () {
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
// margin-top: env(safe-area-inset-top);
  .burger-button {
    padding-right: 5px;
    padding-left: 20px;
  }
  .modal-wrapper {
    margin-top: 88px;
  }
  .modal-menu {
    background-color: rgba(0,0,0,.4);
    //margin-top: 44px; // add 16px for status bar

    @media all and (display-mode: standalone) and (orientation : portrait) { // #app-nav,
      margin-top: 60px;
    }
    @media all and (display-mode: standalone) and (orientation : landscape) { // #app-nav,
      margin-top: 44px;
    }
  }
  .modal-menu-iPhoneX {
    @media all and (display-mode: standalone) and (orientation : portrait) { // #app-nav,
      margin-top: 88px;
    }
    @media all and (display-mode: standalone) and (orientation : landscape) { // #app-nav,
      margin-top: 44px;
    }
  }
  .nav-links {
    position: absolute;
    top:0px;
    right:0;
    width: 100%;
    max-width: 414px;
  }
</style>
