<template id="main">

  <v-ons-page v-if="loading">
    <MobileTopNav :showCatalog="false" :showLogo="false" :category="currentCategory"/>
    <div style="color: white">
      <p>Laster categorier...</p>
    </div>
  </v-ons-page>

  <v-ons-page v-else>

    <MobileTopNav :showCatalog="false" :showLogo="false" :category="currentCategory"/>

    <v-ons-toolbar class="red_bg">
      <div class="center"> Min tittel </div>
      <div class="right">
        <v-ons-toolbar-button icon="ion-navicon, material: md-menu"></v-ons-toolbar-button>
      </div>
    </v-ons-toolbar>

    <div class="content">
      <v-ons-tabbar hide-tabs swipeable tab-border id="tabbar" style="margin-top: 29px;"
        :tabs="categoryTabs"
        @postchange="postChange"
        @logIndexes="logIndexes"
      ></v-ons-tabbar>
      <CategoryTabbarScroller :categories="categories" ref="tab-scroller" />
    </div>

  </v-ons-page>
</template>

<script>

import MobileTopNav from '@/components/menus/MobileTopNav'
import VideoNavLinks from '@/components/video_nav/VideoNavLinks'
import { Cache } from 'aws-amplify'

import CategoryTabbarScroller from '@/components/video_nav/CategoryTabbarScroller'

// import { components } from 'aws-amplify-vue'
import { listCategories } from '@/graphql/queries'
import gql from 'graphql-tag'
import client from '@/utils/fagfilm-video-client'
import { loadContent } from '@/utils'

// Data needed: category, categories, cachedTabs, loading
export default {
  components: {
    VideoNavLinks,
    CategoryTabbarScroller,
    MobileTopNav
  },
  data () {
    return {
      categories: [],
      categoryTabs: undefined,
      loading: true,
    }
  },
  async created () {
    this.categories = await loadContent() // START LOADING
    // console.log('await this.categories', this.categories)

    this.categoryTabs = this.createCategoryTabs (this.categories)
  // console.log('categoryTabs', this.categoryTabs)
    
    // console.log('this.loading', this.loading)
    this.loading = false // LOADING FINNISHED
    // console.log('this.loading', this.loading)
  },

  computed: {
    chapters () {
      return this.video.chapters.items
    },
    currentCategory () {
      const category = this.categories.find(category => category.slug === this.$route.params.categorySlug)
      return category
    },
    cachedTabs () {
      const tabs = this.createCategoryTabs(this.categories)
      return tabs
    },
  },
  methods: {
    // cachedTabs () {
    //   const tabs = this.createCategoryTabs(this.categories)
    //   return tabs
    // },
    createCategoryTabs (categories) {
      // console.log('createCategoryTabs categories', categories)
      return categories.map(category => {
        // console.log('CategoryTabs slug: ', category.slug, 'SLUG:', this.$route.params.categorySlug )
        const active = this.$route.params.categorySlug === category.slug
        return {
          page: VideoNavLinks,
          active: active, // active tab on create !
          props: {
            category: category
          },
          key: category.id
        }
      })
    },
    postChange (e) {
      const scroller = this.$refs['tab-scroller'].$refs['scroller'].$el // the toolbar that has page width
      const clientWidth = scroller.clientWidth
      const container = this.$refs['tab-scroller'].$refs['scroll-container']
      const elem = this.$refs['tab-scroller'].$refs[e.index][0].$el
      const scrollWidth = elem.scrollWidth
      const elemPos = elem.offsetLeft
      // Calculate where to scroll:
      const scrollToPos = elemPos - (clientWidth / 2) + (scrollWidth / 2)
      container.scrollLeft = scrollToPos
    },
    changeTab () {
      this.tabbarIndex = 1
    },
    changeButton () {
      this.segmentIndex = 1
    },
    logIndexes () {
    // console.log('active button index', this.segmentIndex);
    // console.log('active tab index', this.tabbarIndex);
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
