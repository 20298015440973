<template lang="html">
  <v-ons-page>
    <MobileTopNav :showLogo="true" :showHome="true"/>
    <div class="callback-page">
      <!-- <h2 style="position:relative; top: 290px">Logger inn med Feide</h2> -->
      <p v-show="visible">Det tar bare litt tid noen ganger...</p>
      <p v-show="!visible">Logger inn med Feide ...</p>
      <img v-show="visible" src="@/assets/utolmodig.gif" id="venter"/>
      <img v-show="!visible" src="@/assets/feide.png" id="feide-logo"/>
      <v-ons-progress-bar indeterminate id="login-progress"></v-ons-progress-bar>
      
    </div>
     
  </v-ons-page>
</template>

<script>

// <v-ons-progress-circular modifier='large' indeterminate id="login-progress"></v-ons-progress-circular>
import MobileTopNav from '@/components/menus/MobileTopNav'
import { API, Auth } from 'aws-amplify'
// import jwt_decode  from 'jwt-decode';
import { getFeideCredentials } from '@/graphql/queries'
// import library from '@/views/Library'
// import getUserInfo from '@/licenceManagement/getLicenseUser'
import { redirect_uri } from '@/constants'
import axios from 'axios'
// const redirect_uri = `https://${window.location.hostname}/callback` // husk å ender i Auth også
// const redirect_uri = 'https://dev.due2tlhvjddvh.amplifyapp.com/callback'

export default {
  components: {
    MobileTopNav
  },
  data () {
    return {
      user: {},
      visible: false
    }
  },
  async created () {
    // set timer for å bytte ventebilde
    setTimeout(function () {
       this.visible = true
    }.bind(this), 6000)

    // console.log('|||||| this.$route.query: ||||||||', this.$route.query)

    const code = this.$route.query.code // params
    const feideHash = this.$route.query.state
    
    console.log('code && feideHash', code, feideHash)
    if(code && feideHash) {
      let feideCreds
      try {
        const creds = await this.getFeideCredentialsQuery(code, redirect_uri)
        feideCreds = creds.data.getFeideCredentials
        console.info('feideCreds:\n', feideCreds)
        localStorage.id_token_hint = feideCreds.id_token
      } catch(e) {
        throw new Error({message: 'Det gikk galt:\n', e})
      }
      // access token allows a client application to access a specific resource to perform specific actions on behalf of the user.
      // var decoded = await jwt_decode(feideCreds.id_token); // Decoding
      // const feideUserInfo = await this.getUserInfo(feideCreds.access_token) // tilbruk senere for å hente lisens
      // console.log('**** FEIDE USER INFO: ******\n', JSON.stringify(feideUserInfo))
      // const schoolOwner = await this.getSchoolDetails('974589648') // feideUserInfo.orgNumber
      // console.log('schoolOwner:\n', schoolOwner)
      // const { email, name } = decoded


      let hashedState  = localStorage.feideHash
      // console.log('hashedState: ', hashedState)
      // console.log('feideHash: ', feideHash)
      const hashMatch = hashedState === feideHash
      // console.log(`${hashedState} === ${feideHash} = ${hashMatch}`)
      if(hashMatch) {
        this.signIn (feideCreds)
      } else {
        // console.log('fagfilm hash does not match hash returned from feide')
        alert('Det oppstod en innloggingsfeil. Vi beklager dette. Prøv igjen om en stund')
        // throw new Error('Det oppstod en innloggingsfeil.')
      }
    }
  },
  methods: {
    async getFeideCredentialsQuery (code, url) {
      // legg ved hash session her. hent fra this.$store.getters.openIdCreds (burde dette lagres server side før bruk ? antagelig ikke)
      console.log("code:",code)
      try {
        const creds = await API.graphql({
          query: getFeideCredentials,
          variables: {
            code: code,
            redirect_uri: url
          },
          authMode: 'API_KEY'
        })
        console.log('creds:\n',creds)
        return creds
      } catch (e) {
        console.log('Innlogging til Feide gikk galt. Kunne ikke hente feide innloggings-token\n', e)
        console.log('feil', e)
      }
    },
    signIn (feideCreds) {
      console.log('signing in')
      Auth.federatedSignIn(
          'auth.dataporten.no', // domain
          {
              token: feideCreds.id_token, // proves that the user has been authenticated
              // identity_id: // Optional
              expires_at: feideCreds.expires_in * 1000 + new Date().getTime() // the expiration timestamp
          },
          // { email, name, test: 'test' }
      ).then( () => { // cred
          // If success, you will get the AWS credentials
          console.log('FEDERATED CRED:', cred)

          return Auth.currentAuthenticatedUser();
      }).then(user => {
          // If success, the user object you passed in Auth.federatedSignIn
          console.log('FEDERATED USER:', user)
          // var cognitoUser = jwt_decode(user.token);
          this.$store.commit('setUser', user)
          // console.log('user\n', user);
          // this.$router.push({ name: 'library'})
          // this.$mapRouteStack.push(library)
          // this.$emit('push-page', library);

          // USer is loged in: now can access info about school etc..
      }).catch(e => {
          console.log('Innlogging til Feide gikk galt.\n', e)
      })
    },
    getInstitution(info) {
      const {id, displayName, orgType} = info
      const orgNumber = id.split(':').slice().pop().split("NO")[1]
      return { orgNumber, displayName, orgType }
    },
    async getUserInfo (access_token) {
      // console.log('getUserInfo access_token: ', access_token)
      // Get user info 
      let info
      try {
        info = await axios.get('https://groups-api.dataporten.no/groups/me/groups',{ // 
          headers: {
            'Authorization': `Bearer ${access_token}`
          }
        })
      } catch (e) {
        // console.log('Feil:\n', e.Error)
        throw new Error('Noe gikk galt. Kunne ikke hente brukerinfo.\n', e)
      }

      // console.log('info data 0', info.data)
      const { eduOrgLegalName, postalAddress, norEduOrgNIN, membership: { primaryAffiliation }  } = info.data[0] // kommune / skoleeier
      const { orgNumber, displayName, orgType } = this.getInstitution(info.data[1]) // Skole
      return {
        primaryAffiliation,
        norEduOrgNIN,
        eduOrgLegalName,
        postalAddress,
        orgNumber,
        displayName,
        orgType
      }
    },
    async getSchoolDetails (orgnr) {
      try {
        const res = await axios({
          method: 'GET',
          url: `https://data-nsr.udir.no/v3/enhet/${orgnr}`,
          headers: {
            'Accept': 'application/json'
          },      
        })
        return res.data
      } catch (e) {
        alert('Noe gikk galt. Kunne ikke hente organisasjonsnummer\n', e)
        // console.log(e)
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.callback-page {
    background: rgb(5, 26, 43);
    padding:20px;
    height: calc(100vh - 84px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      font-size: .9em;
    }
    img#feide-logo {
      width: 25vw;
      min-width: 150px;
      max-width: 550px;
      position:relative;
      top: 1.5px;
      right: 0px;
    }
    img#venter {
      width: 36vw;
      min-width: 150px;
      max-width: 550px;
      position:relative;
      // top: 1.5px;
      // right: 0px;
    }
    ons-progress-bar#login-progress {
      width: 25vw;
      min-width: 150px;
      min-height:100px;
      max-width: 550px;
      max-height:550px;
      color: green;
    }    
  }

  #call-link {
    display: inline-block;
    width: 115px;
    border-radius: 5px;
    margin-top: .7em;
    margin-left: -1px;
    color: white;
    background-color:rgb(7, 36, 59);// #CC0000;
    padding: 0.6em 0.9em;
    text-decoration: none;
    #phone {
      margin-right: 7px;
    }    
    a:hover {
      cursor: pointer;
      background-color:rgb(0, 153, 255) !important;
    }

  }
</style>