<template lang="html">
  <!--img src="./count-down.gif" alt="Laster inn fagfilmer" class="cover"/-->
  <div class="videoBgWrapper">
      <h1>Loading...</h1>
      <video loop muted autoplay poster="./count-down-720p.jpg" class="videoBg">
          <!--source src="videosfolder/video.webm" type="video/webm"-->
          <source src="./count-down-720p.mp4" type="video/mp4">
          <!--source src="videosfolder/video.ogv" type="video/ogg"-->
      </video>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
  // img {
  //   width: 100%;
  //   display: inline-block;
  // }
  // .cover {
  //   width: 100%;
  //   height: 100%;
  //   object-position: 50% top;
  //   object-fit: cover;
  // }

  .videoBgWrapper {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      z-index: -100;
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .videoBg{
      position: absolute;
      height: 100%;
      @media (min-aspect-ratio: 16/9) {
        height: auto;
        width: 100%;
      }
  }
</style>
