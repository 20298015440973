<template lang="html">

  <router-link v-if="$root.isTouchScreenDevice"
    :to="{name: 'player', params: {categorySlug: this.category.slug, videoSlug: video.slug, chapter: 'kapittel-0:start'}}"
    tag="div"
    class="slide-link">
      <img :src="thumb(video.fileName, video.posterTime, $root.Constants.THUMBNAIL_WIDTH)" class="video-poster">
      <div :style="{backgroundColor: categoryColor(category.id)}" class="title-bg">
        <div class="video-title-area">
            <span>{{video.title}}</span>
        </div>
      </div>
  </router-link>
  <div class="slide-link" v-else>
    <img :src="thumb(video.fileName, video.posterTime, $root.Constants.THUMBNAIL_WIDTH)" class="video-poster">
    <div :style="{backgroundColor: categoryColor(category.id)}" class="title-bg">
      <div class="video-title-area">
          <span> {{video.title}}</span>
      </div>
    </div>
  </div>

</template>

<script>
import { thumb } from '@/utils'
export default {
  props: ['video', 'category'],
  data () {
    return {

    }
  },
  methods: {
    setVideoUrl (video) {
    // console.log(video.id)
      // change the parameters in the url and trigger the watch method
      // console.log('category.slug', this.category.slug)
      // var myNavigator = document.getElementById('app-nav');
      // myNavigator.pushPage('page2.html');
      // let url
      this.$router.push({ name: 'player',
        params: { categorySlug: this.category.slug, videoSlug: video.slug, chapter: 'kapittel-0:start' } })
    },
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },
    categoryColor (id) {
      const num = parseInt(id)
      // console.log('color ', color, 'id ', num)
      let color
      switch (num) {
        case 0:
          // console.log('switch id', num)
          color = 'rgba(230, 138, 0, 1)' // '#e68a00' // Valgprosessen
          break
        case 1:
          color = 'rgb(177, 85, 255, 1)' // '#B155FF' // akademisk yrke
          break
        case 2:
          color = 'rgba(95, 167, 42, 1)' // '#5FA72A' // yrkesforb
          break
        case 3:
          color = 'rgba(89, 89, 89, 1)' // '#595959' // studieforb
          break
        case 4:
          color = 'rgba(85, 187, 255, 1)' // '#55BBFF' // håndverk og service
          break
        case 5:
          color = 'rgba(117, 26, 255, 1)' // '#751aff' // Utvalgte VG2
          break
        case 6:
          color = 'rgba(255, 77, 77, 1)' // '#ff4d4d' // For lærere
          break
      }
      return color
    }
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
  .slide-link {
    margin-right: .3em;
    img {
      width: 100%;
      height: auto;
    }
    &:hover {
      cursor: ew-resize
    }
    img.video-poster {
      display: block;
    }
    div.video-title-area {
      height: 2.7rem;
      display: flex;
      flex-direction: column;
      padding-left:4px;
      padding-right:4px;
      span {
        line-height: 1.1rem;
        margin-top: auto;
        margin-bottom: auto;
        text-align: center;
        font-size: .85rem
      }
    }
  }

</style>
