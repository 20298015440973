import { render, staticRenderFns } from "./PlayerNavBar.vue?vue&type=template&id=4629cfec&scoped=true&lang=html&"
import script from "./PlayerNavBar.vue?vue&type=script&lang=js&"
export * from "./PlayerNavBar.vue?vue&type=script&lang=js&"
import style0 from "./PlayerNavBar.vue?vue&type=style&index=0&id=4629cfec&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4629cfec",
  null
  
)

export default component.exports