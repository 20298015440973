<template>
  <v-ons-page>
    <fullscreen ref="fullscreen" @change="fullscreenChange">
      <div id="video-page">

        <div id="nav-area">
          <PlayerNavBar :category="video.category" />
        </div>
          <PlayerNavBarTransparant  :category="video.category" />
      

        <div id="player-area">
          <Player v-if="video" :video="video"/>
        </div>

        <div id="title-area" >
          <div id="title-grid">
            <div></div>

            <div id="title">{{video.title}}</div>

            <span id="fullscreen-button" @click="toggle">
              <span>fullskjerm</span> 
              <v-ons-icon size="1.1em" id="fullscreen-icon" icon="fa-expand"></v-ons-icon>
            </span>
          </div>
        </div>
        
        <div id="chapters-area">
        <ChaptersLinksV :video="video"/>
        <ChaptersLinksH :video="video"/>
        </div>

      </div>
    </fullscreen>
  </v-ons-page>
</template>

<script>

/*
<!-- <Player v-if="video" :video="video" :width="playerAreaWidth"/> -->
          <!-- <ChaptersLinks :video="video" :scrollerHorizontal="scrollerHorizontal" :height="this.uiHeight - this.playerAreaHeight - 44 - 44 + 'px" :class="{}"/> -->
          <!-- <ChaptersLinks :video="video" :scrollerHorizontal="scrollerHorizontal" :height="this.uiHeight - this.playerAreaHeight" :class="{'hidden': smallChapters}"/> -->
          <!-- <ChaptersLinks :video="video" :height="chaptersHeight" :width="chaptersWidth" :layoutClass="'chapter-' + layoutClass" /> -->
*/
// høyre sidebar med top meny og title-bar ved high aspect og lav bredde eks 16/10 max 760px, samt heigh aspect og lav høyde.
// kun video og kapitler i en range av low aspect window fra max 16/10 - 16/11

import Player from '@/components/player/PlayerBaseView'
import PlayerNavBar from '@/components/player/PlayerNavBar'
import PlayerNavBarTransparant from '@/components/player/PlayerNavBarTransparant'
import ChaptersLinksV from '@/components/chapters/ChaptersLinksV'
import ChaptersLinksH from '@/components/chapters/ChaptersLinksH'
// import vhCheck from 'vh-check'

// vhCheck({force: true, bind: true})

export default {
  props: ['video'],
  components: { Player, PlayerNavBar,PlayerNavBarTransparant, ChaptersLinksV, ChaptersLinksH },
  data () {
    return {
      fullscreen: false
    }
  },
  methods: {
    toggle () {
      console.log('Toggle')
      this.$refs['fullscreen'].toggle() // recommended
      // this.fullscreen = !this.fullscreen // deprecated
    },
    fullscreenChange (fullscreen) {
      this.fullscreen = fullscreen
    }
  },

}
</script>

<style scoped lang="scss">
  #video-page {
    // background: gray;
    width: 100vw;
    height: calc(100vh - var(--vh-offset, 0px));
    display: grid;
    align-content: start;
    grid-template-rows: 44px auto 44px 1fr;
    grid-template-areas:
      "nav"
      "player"
      "title"
      "chapters";
  }  
  #nav-area {
    grid-area: nav;
    height: 44px;
  }  
  #player-area {
    grid-area: player;
    // background: rgb(14, 157, 253);
    width: 100vw;
    height: calc(100vw * 9/16);
  }
  #title-area {
    grid-area: title;
    width: 100%;
    #title-grid {
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      align-content: space-between;
    }

    #title {
      font-weight: bolder;
      margin-top: 10px;
      font-size: 1.2em;
    }
    #fullscreen-button {
      width: 100px;
      justify-self: end;
      margin-right: 10px;
      margin-top: 4px;
      display: grid;
      grid-template-columns: auto auto;
      align-content: center;
      span {
        align-self: center;
        font-weight: normal;
        font-size: 13px;
      }
      #fullscreen-icon {
        align-self: center;
        display: inline;
      }
    }
  }
  #chapters-area {
    grid-area: chapters;
    display: grid;
    // background: green;
    height: calc( (100vh - var(--vh-offset, 0px)) - (100vw * 9/16) - 88px);
    overflow: hidden;
  }

  
  #h-chapters { display: none }
  /* portrait width navigation and horizontal scroller */
  @media only screen and (min-aspect-ratio: 125/100) {
    #h-chapters { display: grid }
    #v-chapters { display: none }
    #title-area { display: none }
    #video-page {
      display: none;
      display: grid;
      grid-template-rows: 44px auto auto;
      grid-template-areas: 
        "nav"
        "player"
        "chapters";
    }
    #chapters-area {
      height: calc( (100vh - var(--vh-offset, 0px)) - (100vw * 9/16) - 44px);
    }
  }

  /* iPadLandscape crop 22px from video top  and horizontal scroller*/
  @media only screen and (min-aspect-ratio: 145/100) { // 145
    // #v-chapters { display: none } // v-chapters has the id from the ChaptersLinksV component
    #title-area, #nav-area { display: none }
    #video-page {
      // background: pink;
      // display: none;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-areas: 
        "player"
        "chapters";
    }
    #player-area {
      margin-top: -22px;
      // background: rgb(145, 14, 253);
      width: 100vw;
      height: calc((100vw * 9/16));
    }
    #chapters-area {
      height: calc((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) + 22px);
    }
  }

  @media only screen and (min-aspect-ratio: 170/100)  {
    #h-chapters { display: none }
    #v-chapters { display: grid }
    #title-area, #nav-area { display: block }
    #video-page {
      // display: none;
      display: grid;
      grid-template-rows: 44px auto 44px;
      grid-template-columns: auto 1fr;

      grid-template-areas: 
        "nav chapters"
        "player chapters"
        "title chapters";
    }
    #player-area {
      margin-top: 0px;
      margin-right: -8px; // Make more space for chapter width
      margin-left: -8px;
      // background: rgb(14, 157, 253);
      height: calc(100vh - 88px);
      width: calc((100vh - 88px) * 16 / 9);
    }
    
    #chapters-area {
      // background: green;
      height: 100vh;
      z-index: 100;
    }
  }

  @media only screen and (min-aspect-ratio: 174/100)  {
    #video-page {
      display: grid
    }
    #player-area {
      margin: 0px; // remove extra chapter width space
    }
  }


  @media only screen and (min-aspect-ratio: 210/100)  { // 164
    #title-area, #nav-area { display: block }
    #video-page {
      display: grid;
      // display: none;
      grid-template-areas: 
        "player nav"
        "player chapters"
        "player title";
    }
    #player-area {
      // background: rgb(14, 157, 253);
      height: 100vh;
      width: calc((100vh - var(--vh-offset, 0px)) * 16 / 9);
    }
    #chapters-area {
      height: calc((100vh - var(--vh-offset, 0px)) - 88px);
    }
  }


</style>
