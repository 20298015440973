<template>
  <div class="collapse">
    <div @click="show=!show;" class="header">
      <slot name="header"></slot>
      <font-awesome-icon v-if="!show" :icon="chevronDown" size="1x" />
      <font-awesome-icon v-else :icon="chevronUp" size="1x" />
    </div>
    <transition>
      <div v-if="show" class="collapse-content">
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'Collapse',
  components: {FontAwesomeIcon},
  data () {
    return {
      show: false,
      chevronUp: faChevronUp,
      chevronDown: faChevronDown,
    }
  },
}
</script>

<style lang="scss" scoped>
$border-color: #dee2e6;

.collapse {
  width: 300px;
}
.collapse-content {
  padding: 1rem;
  border-right: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}
.header {
  cursor: pointer;
  user-select: none;
  padding: 0.5rem 1rem;
  border: 1px solid $border-color;
  /* background-color: #f3f3f3; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}
</style>
