<template lang="html">
  <v-ons-toolbar id="category-toolbar" ref="scroller">
    <div class="center scroll-container scrollable" ref="scroll-container">
      <div class="scroll-content">

          <v-ons-segment tabbar-id="tabbar" modifier="material"
            style="width: 100%" @postchange="postChange($event)">
            <v-ons-button v-for="(category, index) in categories"
              :ref="index"
              :key="index"
              >
              {{category.name}}
            </v-ons-button>
          </v-ons-segment>


      </div>
    </div>
  </v-ons-toolbar>
</template>

<script>

export default {
  props: ['categories'],
  data () {
    return {
      segmentIndex: 0,
      // categories: [{id: '3', name: 'Yrkesforberedende'}, {id: '7', name: 'cat 1'},{id: '1', name: 'cat 1'},{id: 4, name: 'cat 1'}]
    }
  },
  created () {
    this.changeButton(this.getIndex())
  // console.log('Tab bar created with categories', this.categories)
  },
  methods: {
    postChange (event) {
      const slug = this.categories[event.index].slug
    // console.log('event.segmentItem index', slug)
      this.$router.replace({ name: 'category', params: { categorySlug: slug } })
    },
    log (...args) {
    // console.log(...args)
    },
    changeTab () {
    // console.log('Y changeTab')
      this.tabbarIndex = 1
    },
    changeButton (key) {
    // console.log('X changeButton: ', key)
      this.segmentIndex = key
    },
    logIndexes () {
    // console.log('active button index', this.segmentIndex)
    // console.log('active tab index', this.tabbarIndex)
    },
    getIndex () {
    // console.log('categories', this.categories)
      const categorySlug = this.$route.params.categorySlug

      const index = this.categories.findIndex(c => c.slug === categorySlug)
    // console.log('category: ', categorySlug, ', index', index)
      return index
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-bar-wrapper {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    -ms-scroll-snap-type: mandatory;
  }
  .scroll-container {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    margin:0;
    padding:0;
  }
  .scroll-content {
    min-width: 1250px;
    line-height: 26px;
    padding-bottom: 6px;
  }

  ons-toolbar.toolbar#category-toolbar {
    height: 38px; // 32
  }
</style>
