// const IMAGESERVER = 'https://25n6ehjrch.execute-api.eu-west-1.amazonaws.com/prod/'
const IMAGESERVER = 'https://d1j2lkoxx8uycr.cloudfront.net/'

import Store from '@/store'
import { Cache, API, Auth } from 'aws-amplify'
import { listCategorys } from '@/graphql/customQueries'
export const ToSMPTEu = function (secs) {
  return ToSMPTE(secs).split(':').join('_')
}

// returnerer vanlig: 00:12:12:05
const ToSMPTE = function (secs) {
  const secNum = parseInt(secs, 10)
  const hours = Math.floor(secNum / 3600) % 24
  const minutes = Math.floor(secNum / 60) % 60
  const seconds = secNum % 60
  const SMPTEu3 = [hours, minutes, seconds]
    .map(v => v < 10 ? '0' + v : v)
    .join(':')
  let tenths = (parseFloat(secs) % 1).toFixed(2)
  tenths = tenths >= 0.96 ? 0.96 : tenths
  let frames = parseInt((tenths * 25).toFixed(0))
  frames = frames < 10 ? '00' : frames
  const SMPTE = SMPTEu3 + ':' + String(frames)
  return SMPTE
}

const secondsToMMSS = (secs) => {
  const mmss = ToSMPTE(secs).split(':')
  return `${mmss[1]}:${mmss[2]}`
}

const thumb = (fileName, time, width) => {
  const SMTPu = ToSMPTEu(time)
  const imageUrl = IMAGESERVER + 'SMPTE_' + SMTPu + ',w_' + width + '/' + fileName + '.jpg'
  return imageUrl
}

const sortByName = (arr) => {
  return arr.sort(function (a, b) {
    return a.name.localeCompare(b.name, 'no', { sensitivity: 'accent' })
  })
}
const sortByTitle = (arr) => {
  return arr.sort(function (a, b) {
    return a.title.localeCompare(b.title, 'no', { sensitivity: 'accent' })
  })
}
const sortByStartTime = (arr) => {
  return arr.sort(function (a, b) {
    return a.start - b.start
  })
}

const isPwa = () => {
  const isInStandaloneMode = () =>
    (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone)

  if (isInStandaloneMode()) {
    return true
  }
  return false
}

async function loadImages (){
  const categories = Cache.getItem('categories')
  const catVideos = categories.map(cat => cat.videos.items)
  const videos = [].concat.apply([], catVideos)
  const imagesPromises = videos.map ( async video => {
    const img = new Image()
    img.src = thumb(video.fileName, video.posterTime, 720) // this.$root.Constants.THUMBNAIL_WIDTH
    return await img.decode();
  })
  return await Promise.allSettled(imagesPromises)
}

async function loadContent () {
  const credentials = await Auth.currentCredentials();
  // console.log('credentials:\n', credentials)

  let authMode = 'AWS_IAM' // auth + unauth
  console.log('credentials:\n', credentials)
  const feideHash = localStorage.getItem('feideHash')
  if(credentials && credentials.authenticated) {
    if(feideHash) {
      authMode = 'AWS_IAM'
    } else {
      authMode = 'AMAZON_COGNITO_USER_POOLS'
    }
  } else {
    authMode = 'AWS_IAM'
  }

  Store.commit('setLoading', true)
  // console.log('LOADING CACHE')
  let categories
  Store.commit('setLoading', true)
  /* HER ER DET ENESTE KALLET PÅ ALL VIDEO !!!! */
  console.log('utils/loadContent: HENTER KATEGORIER')  
  let result
  try {
    result = await API.graphql({
      query: listCategorys,
      authMode: authMode
    })
  } catch (e) {
    alert('Noe gikk dessverre galt. Prøv å logge ut og logge inn igjen (Ikke bruk Feide inntil videre.)')
    console.log('loadContent i utils FEILET:', e)
  }

  const catData = result.data
  console.log('catData:\n', catData)
  categories = catData.listCategorys.items
  console.log('categories items: ', categories)

  const sortedCats = categories.sort( (a,b) => { return a.rank - b.rank })
  // Filtrer for kategori 0 (ikke valgt)
  const filteredCats = sortedCats.filter( cat => { return cat.id !== '0'})
  // loadImages()
  return filteredCats
}

export {
  thumb,
  sortByName,
  sortByTitle,
  sortByStartTime,
  ToSMPTE,
  secondsToMMSS,
  isPwa,
  loadContent,
  loadImages
}
