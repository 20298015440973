import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Callback from '../views/Callback.vue'
import Library from '@/views/Library'
import Authenticator from '@/components/enter/Auth'
import VideoNav from '@/views/VideoNav'
import Player from '@/views/Player'
import Skolekode from '@/components/enter/Skolekode'
import Personvern from '@/views/Privacy'
import Bestill from '@/views/Order'
import Logout from '@/views/Logout'

Vue.use(VueRouter)
  
  const routes = [
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { topNav: false, home: true },
    children: [
      {
        path: 'filmer',
        name: 'library',
        component: Library,
        children: [
          {
            path: 'kategori/:categorySlug',
            name: 'category',
            props: true,
            component: VideoNav,
            children: [
              {
                path: 'tittel/:videoSlug/:chapter',
                name: 'player',
                component: Player
              }
            ]
          }
        ]
      },
      {
        path: '/logout',
        name: 'logout',
        component: Logout // () => import('@/views/Order.vue')
      },
      {
        path: '/bestill',
        name: 'order',
        component: Bestill // () => import('@/views/Order.vue')
      },
      {
        path: '/personvern',
        name: 'privacy',
        component: Personvern //() => import('@/views/Privacy.vue') // '@/views/Privacy.vue'
      },
      {
        path: '/auth',
        name: 'Authenticator',
        component: Authenticator,
        props: {
          id: 'fagfilm-authenticator'
        }
      },
      {
        path: '/innmelding',
        name: 'CreateAccount',
        component: Authenticator,
        props: {
          id: 'fagfilm-authenticator'
        }
      },      
      {
        path: '/skolekode',
        name: 'Skolekode',
        component: Skolekode,
      },

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
