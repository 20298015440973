<template lang="html">
  <div id="h-chapters">
    <template v-for="(chapter, key) in video.chapters.items">
      <div class="h-chapter" :key="key">
        <ChapterLinkH :chapter="chapter" :chapters="chapters" :video="video" :chapterNumber="key + 1"/>
      </div>
    </template>
  </div>
</template>

<script>

import ChapterLinkH from './ChaptersLinksLink-hor'

export default {
  name: 'ChaptersBaseLinks',
  props: ['video', 'height','width', 'layoutClass'], // video does not contain chapters
  data () {
    return {
      isLoading: false,
      chapters: [],
      scrollerHeight: 0,
      uiHeight: 0
    }
  },
  components: { ChapterLinkH },
  mounted () {
    const playerAreaObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.target.id === 'h-scroller') {
          this.scrollerHeight = entry.contentRect.height
        }
      }
    })
    playerAreaObserver.observe(document.querySelector("#h-chapters"))

  },
  computed: {
    chapterWidth () { return this.scrollerHeight * 16/9 } // this.scrollerHeight * 16/9
  }

}
</script>

<style lang="scss" scoped>
  #h-chapters {
    width: 100vw;
    // background: pink;
    display: grid;
    column-gap: .3em;
    grid-auto-flow: column;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    grid-template-columns: repeat(auto-fill, minmax( calc( ((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) - 44px) * 16 / 9) ,1fr)); // height * 16 / 9
    grid-auto-columns: minmax( calc( ((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) - 44px) * 16 / 9) ,1fr);
    // &::-webkit-scrollbar {
    //   display: none;
    // }
    @media only screen and (min-aspect-ratio: 145/100) {
      grid-template-columns: repeat(auto-fill, minmax( calc( ((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) + 22px) * 16 / 9) ,1fr));
      grid-auto-columns: minmax( calc( ((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) + 22px) * 16 / 9) ,1fr);
    }
  }
  .h-chapter {
    @media only screen and (min-aspect-ratio: 125/100) {
      height: calc((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) - 44px);
    }    
    // @media only screen and (min-aspect-ratio: 145/100) {
    //   height: calc((100vh - var(--vh-offset, 0px)) - (100vw * 9/16) + 22px);
    // }
  }
</style>
