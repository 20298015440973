<template>
  <router-link
  :to="{ name: 'player',params: { 
    categorySlug: video.categorySlug,
    videoSlug: video.slug,
    chapter: `kapittel-${chapterNumber}:${formatSlug(chapter.title)}` } 
  }"
  tag="div" id="chapterWrapper" class="chapter" :style="{'background-image': `url(${imageUrl})`, height: chapterHeight - 4 + 'px',}"
  >
    <div class="chapter-title-area" :style="{ padding: titlePadding, 'font-size': titleSize, 'font-weight': fontWeight }">
      <span class="title">{{chapter.title}}</span>
      <span class="time" :style="{display: showTime}">{{formatTime(chapter.start)}}</span>
    </div>
    <div class="chapter-number number-active" :style="chapterNumberStyle">
      <span >{{chapterNumber}}</span>
    </div>
  </router-link>
</template>
<script>

import { secondsToMMSS, thumb } from '@/utils'

export default {
  props: ['chapter', 'chapters', 'video', 'chapterNumber', 'delay'], // 'chapter', 
  data () {
    return {
      chapterWidth: 0,
      chapterHeight: 0
    }
  },
  mounted () {
    const playerAreaObserver = new ResizeObserver((entries) => {
      const chapterEl = entries[0]
      this.chapterWidth = chapterEl.contentRect.width
      
      this.chapterHeight = this.chapterWidth * 9/16
      // console.log('this.chapterWidth', chapterEl.contentRect.width)
    })
    playerAreaObserver.observe(document.querySelector("#chapterWrapper"))
  },
  methods: {
    onLoadHandler () {
    // // console.log('loaded: ')
    },
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },
    formatSlug (title) {
      const slugNoSpace = title.replace(/\s+/g, '-').toLowerCase()
      return slugNoSpace.replace(/\?/g, '')
    },
    formatTime (start) {
      return secondsToMMSS(start)
    }
  },
  computed: {
    imageUrl () {
      return this.thumb(this.video.fileName, this.chapter.posterTime, 600) + 'px'
    },
    titlePadding () {
      const paddingVar = this.chapterHeight > 90 ? 0.13 : 0.20;
      return Math.round(this.chapterWidth * paddingVar) / 100 + 'em'
    },
    titleSize () {
      if ( this.chapterHeight < 100) {
        return '0.7em'
      }
      if ( this.chapterHeight > 250) {
        return '1.8em'
      }
      return Math.round(this.chapterHeight * 0.7) / 100 + 'em'
    },
    fontWeight () {
      if ( this.chapterHeight < 120) {
        return 'bold'
      }
      return 'normal'
    },
    chapterNumberStyle () {
      const dimension = (5 + Math.round(this.chapterHeight * 20) / 100)
      return {
        'margin-top': Math.round(this.chapterHeight * 6) / 100 + 'px',
        'margin-right': Math.round(this.chapterHeight * 7) / 100 + 'px',
        width: dimension - 5 + 'px',
        height: dimension - 5 + 'px',
        'font-size': (Math.round(this.chapterHeight * .70) / 100) + 'em'
      }
    },
    showTime () {
      return this.chapterHeight < 90 ? 'none' : 'block'
    }
  }
}
</script>

<style scoped lang="scss">
// , width: chapterWidth +'px', height: chapterHeight - 4 + 'px',
    @media only screen and (min-aspect-ratio: 125/100) {
      // height: calc(100vh - (100vw * 9/16) - 44px);
    }    
    @media only screen and (min-aspect-ratio: 145/100) {
      // height: calc(100vh - (100vw * 9/16) + 22px);
    }
  .chapter {
    height: 100%;
    width: 100%;
    background-size: contain;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
  }
  .chapter:hover {
    background-size: 120%;
  }
  // :style="{ padding: titlePadding, 'font-size': titleSize, 'font-weight': fontWeight }"
  .chapter-title-area {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    // background: rgba(0, 0, 0, 0.2);
    padding: .7em;
    margin: 0;
    box-sizing: border-box;
  }
  .title {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  }
  .chapter-number {
    float: right;
    text-align: center;
    border-radius: 50%;
    background: rgba(30, 150, 255, 0.7);
    color: white;
    span {
      display: grid;
      align-items: center;
      height: 100%;
    }
  }
  .time {
    float: right;
  }
</style>