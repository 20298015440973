<template lang="html">

  <div id="lib-scroll">

    <span :class="$root.isTouchScreenDevice ? 'm-category-link-bg' : 'm-category-link-bg'">
      <router-link
        :to="{name: 'category', params: {categorySlug: category.slug}}"
        class="category-link"
        >
        {{ category.name }}
      </router-link>
    </span>

    <div v-if="$root.isTouchScreenDevice" class="mobile category-hscroller">
      <template v-for="video in category.videos.items">

          <FilmFrontLinksLink :video="video" :category="category"
            :key="video.id"
            class="slide"
          />

      </template>
    </div>

    <div v-else class="desktop">
      <carousel
        ref="carousel"
        :mouse-drag="true"
        :navigationEnabled="true"
        :paginationEnabled="false"
        navigationPrevLabel="&#10094;"
        navigationNextLabel="&#10095;"
        :perPage="2"
        :perPageCustom="[[0, 2],[500, 2],[800, 3],[1100, 4],[1400,5]]"
        :minSwipeDistance="8"
        :resistanceCoef="20"

        :value="pageNumber"
        >
        <slide v-for="video in category.videos.items"
          :key="video.id"
          style="padding:0;margin:0;"
          @slide-click="slideClick(video)"
          >
          <FilmFrontLinksLink :video="video" :category="category" />
        </slide>
      </carousel>
    </div>

    <div class="m-category-bottom">
      <div>{{category.videos.items.length}} filmer</div>
    </div>

  </div>

</template>

<script>
// red: #cc0000
/*
spacePadding="20"
navigationClickTargetSize="16"
*/
// import { thumb } from '@/utils'

import FilmFrontLinksLink from './FilmFrontLinksLink'

import { thumb } from '@/utils'
export default {
  name: 'VideoLinks',
  props: ['category'],
  data () {
    return {
      pageNumber: 0,
      windowWidth: window.innerWidth,
      isLoading: true
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', () => {
        this.winWidth()
      })
    })
  },
  thumb (fileName, time, width) {
    return thumb(fileName, time, width)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.winWidth)
  },
  components: { FilmFrontLinksLink },
  methods: {
    slideClick (vid) {
      const linkParams = { categorySlug: this.category.slug, videoSlug: vid.slug, chapter: 'kapittel-0:start' }
    // // console.log('SLIDE CLICK linkParams', linkParams)
      this.$router.push({ name: 'player',
        params: { categorySlug: this.category.slug, videoSlug: vid.slug, chapter: 'kapittel-0:start' } })
    },
    catClick () {
      this.$router.push({ path: '/kategorier' })
    },
    winWidth () {
      this.windowWidth = window.innerWidth
    },
    categoryColor (id) {
      const num = parseInt(id)
      // console.log('color ', color, 'id ', num)
      let color
      switch (num) {
        case 0:
          // console.log('switch id', num)
          color = 'rgba(230, 138, 0, 1)' // '#e68a00' // Valgprosessen
          break
        case 1:
          color = 'rgb(177, 85, 255, 1)' // '#B155FF' // akademisk yrke 177, 85, 255, 1
          break
        case 2:
          color = 'rgba(95, 167, 42, 1)' // '#5FA72A' // yrkesforb
          break
        case 3:
          color = 'rgba(89, 89, 89, 1)' // '#595959' // studieforb
          break
        case 4:
          color = 'rgba(85, 187, 255, 1)' // '#55BBFF' // håndverk og service
          break
        case 5:
          color = 'rgba(117, 26, 255, 1)' // '#751aff' // Utvalgte VG2
          break
        case 6:
          color = 'rgba(255, 77, 77, 1)' // '#ff4d4d' // For lærere
          break
      }
      return color
    }
  }

}
</script>

<style lang="scss">
  :root {
    --visible-cards: 2.1;
    @include mquery(tablet) {
      --visible-cards: 3.2;
    }
  }
  .desktop {
    @include mquery(large_mobile) {
      // margin-top: 1.5vw;
    }

    margin-left: auto;
    margin-right: auto;
    width: calc(100% - 80px);

    //carousel navigation buttons:
    button.VueCarousel-navigation-button {
      font-size: 1.6em !important;
      color: #cc0000 !important;
    }
    button.VueCarousel-navigation-prev {
      padding-right: .4em !important;
    }
    button.VueCarousel-navigation-next {
      padding-left: .4em !important;
    }

  }

  #lib-scroll {
    background-color: #191919;
    .category-link-bg {
      background-color: #303030;
      display: inline-block;
      padding: 0.5rem;
      padding-right: 2rem;
    }
    .m-category-link-bg {
      width: 100%;
      text-align: center;
      height: 3.6rem;
      background-color: #191919;
      display: inline-block;
      padding-top: 1.2rem;

      @include mquery(small) {
        height: 3.0rem;
      }
      // @include mquery(large_mobile) {
      //   height: 3.5rem;
      //   padding-top: 1.0rem;
      //   margin-top:1em;
      // }
      // @include mquery(tablet) {
      //   height: 4.5rem;
      //   padding-top: 1.7rem;
      //   margin-top:2em;
      // }
    }
    .category-link {
      display: inline-block;
      color: #fff;
      font-size: 1.4em;
      text-decoration: none;
      border-bottom: 1px solid rgba(255,255,255,.6);
      @include mquery(large_mobile) {
        font-size: 1.7rem;
      }
      @include mquery(small) {
        font-size: 1.2rem;
      }
    }
    .category-link-desktop {
      display: inline-block;
      margin-bottom: 1.2vw;
      color: white;
      margin-left: 60px;
      font-size: 1.9em;
      text-decoration: none;
      border-bottom: 1px solid rgba(255,255,255,.6);
      // @include mquery(large_mobile) {
      //   font-size: 1.7em;
      // }
      // @include mquery(small) {
      //   font-size: 1.2em;
      // }
    }

    .category-films-number-desktop {
      display: block;
      width: 50%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      font-size: 0.8em;
      @include mquery(large_mobile) {
        font-size: 1.2em;
        // margin-top: -0px;
      }
    }

    .category-hscroller {
      width: 100%;
      // margin-bottom: 3em;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.1rem;
      overflow-x: auto;
      grid-auto-columns: calc((100% - 0rem) / var(--visible-cards));

      &::after {
        content: '';
        width: 1rem;
      }

      .slide {
        margin-left: 0.3rem;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .m-category-bottom {
      font-size: .80rem;
      height: 2.2rem;
      background-color: #191919;
      margin-bottom: 1rem;
      div {
        line-height: 2.4rem;
        text-align: center;
      }
      border-bottom: 1px solid #272727
    }
  }

</style>
