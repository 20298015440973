<template>
  <ons-page id="app">
      <v-ons-navigator id="app-nav"
        :page-stack="pageStack"
        @push-page="pageStack.push($event)"
        :pop-page="goBack"
        :animation="transition"
      >
    </v-ons-navigator>
  </ons-page>
</template>
<script>

import Store from '@/store'
import { Hub, Auth, Cache } from 'aws-amplify'
// import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import vhCheck from 'vh-check'
import { post_logout_redirect_uri } from '@/constants'
// const post_logout_redirect_uri = `https://${window.location.hostname}/logout`
import Cookies from 'js-cookie'

export default {
  name: 'App',
  data () {
    return {
      pageStack: "pageStack",
      authState: undefined,

    }
  },
  beforeCreate () {
    Cache.clear()
    Hub.listen('auth', async ({ payload }) => {
      // window.console.log('New hub event', payload.event )
      if (payload.event === 'signIn') {
        const user = await Auth.currentAuthenticatedUser()
        this.$store.commit('setUser', user)
        //redirect to users original entry path:
        if (this.$store.state.entryUrl) {
          const entryUrl = this.$store.state.entryUrl
          this.$store.commit('setEntryUrl', undefined)
          // console.log('entryUrl ', entryUrl )
          if (entryUrl === '/') {
            this.$router.push('/filmer')
          } else {
            this.$router.push(entryUrl)
          }
          
        } else {
          this.$router.push({name: 'home'})
        }
      } else if (payload.event === 'signOut') {
        /* SIGN OUT FROM AWS OR FEIDE */

        // console.log('event signed out, Boolean(Cookies.get(feideHash)', Boolean(Cookies.get('feideHash')) )

        this.$store.commit('setUser', null)
        
        if( localStorage.feideHash ) {
          // console.log('signing out: feideHash exists')
          const endSessionUri = 'https://auth.dataporten.no/openid/endsession'

          const id_token_hint = localStorage.getItem('id_token_hint')
          // console.log('id_token_hint: ', id_token_hint)
          // Cookies.get('feideHash').get('feideCreds').id_token

          // console.log('feideHash og feideCreds deleted on signout')
          localStorage.removeItem('id_token_hint') // getItem
          localStorage.removeItem('feideHash')
          // console.log('id_token_hint', id_token_hint)
          const feideLogoutLink = `${endSessionUri}?post_logout_redirect_uri=${post_logout_redirect_uri}&id_token_hint=${id_token_hint}`
          // console.log('feideLogoutLink:\n', feideLogoutLink)
          window.location.href = feideLogoutLink
        } else {
          // console.log('signing out: feideHash DO NOT exists')
          this.$router.push({name: 'Authenticator'})
          this.$ons.notification.toast('Du ble logget ut', { timeout: 1000, animation: 'ascend' })
        }
      } else {
        try {
          // console.log('not signin/out, payload:', payload)
          // Auth.currentSession()
        } catch (err) {
          console.log('Auth.currentSession() fail')
        }
      }
    })
  },
  created () {
    this.checkUser()
    // console.log('created, constants', this.$root.Constants.THUMBNAIL_WIDTH)
    if (this.$ons.platform.isIPhoneX()) {
      this.isIPhoneX = true
    }
    if (this.$ons.platform.isIPhone()) {
      this.isIPhone = true
    }
    const mapRouteStack = route => this.pageStack = route.matched.map(m => m.components.default)
    mapRouteStack(this.$route)
    this.$router.beforeEach((to, from, next) => mapRouteStack(to) && next())
  },
  mounted () {
    vhCheck()
  },
  methods: {
    goBack () {
      this.$router.push({ name: this.$route.matched[this.$route.matched.length - 2].name })
    },
    checkUser () {
      Auth.currentAuthenticatedUser()
      .then((user) => {
        // window.console.log(`App checkUser, user: ${user}`)
        this.$store.commit('setUser', user)
        // console.log("state user", this.$store.state.user)
      })
      .catch( () => {
        this.$store.commit('setUser', null)
        // window.console.log('App checkUser: user not logged in')
      })
    }    
  },
  computed: {
    transition () {
      return Store.state.transition
    }
  }
}
</script>

<style lang="scss">
  :root {
    --plyr-color-main: #1ac266; // not working
  }
  .browser {
    height: 100vh; // Not stand-alone
  }
  .standalone {
    @media all and (display-mode: standalone) and (orientation: portrait) {
      margin-top: 16px;
      height: calc(100vh - 16px);
    }
    @media all and (display-mode: standalone) and (orientation: landscape) {
      height: calc(100vh);
      background-color: $fagfilm-red
    }
  }
  .iphonex {
    height: 100%;
    @media all and (display-mode: standalone) {
      height: 100vh;
    }
  }
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 200px;
    // margin-top: 16px;
    // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    // padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  }
  #app-nav {
    padding: 0;
    margin: 0;
  }
  body {
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;

    @media all and (display-mode: standalone) and (orientation: portrait) {
      background-color: $fagfilm-red
    }
    @media all and (display-mode: standalone) and (orientation: landscape) {
      background-color: #0d0d0d
    }
  }
  ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
  }

  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: $fagfilm-red;
    outline: 1px solid black;
  }

  .info-page {
    background: rgb(5, 26, 43);
    padding:20px;
    height: calc(100vh - 84px);
    text-align: center;
  }
</style>