<template lang="html">
  <v-ons-page>
    <MobileTopNav :showLogo="true" :showHome="true"/>
    <div class="order-page">
      <h1>Bestilling</h1>
      <p><span style="margin-right: .6em">For bestilling, kontakt Fagfilm på:</span>
        <a id="call-link" href="tel:22571500">
          <ons-icon id="phone" icon="fa-phone"></ons-icon>
          22 57 15 00
        </a>
      </p>
      <small>Åpent 08:00 - 21:00</small>
    </div>
  </v-ons-page>
</template>

<script>
import MobileTopNav from '@/components/menus/MobileTopNav'
export default {
  components: {
    MobileTopNav
  }
}
</script>

<style lang="scss" scoped>
  .order-page {
    background: rgb(5, 26, 43);
    padding:20px;
    height: calc(100vh - 84px);
    text-align: center;
  }
  #call-link {
    display: inline-block;
    width: 115px;
    border-radius: 5px;
    margin-top: .7em;
    margin-left: -1px;
    color: white;
    background-color:rgb(7, 36, 59);// #CC0000;
    padding: 0.6em 0.9em;
    text-decoration: none;
    #phone {
      margin-right: 7px;
    }    
    a:hover {
      cursor: pointer;
      background-color:rgb(0, 153, 255) !important;
    }

  }
</style>
