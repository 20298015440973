<template>
  <v-ons-page class="auth-page">
    <MobileTopNav :showCatalog="true" :showHome="true"/>

    <div class="skolekode-melding">
      <div v-if="!newCodeOk">
        <h1 style="text-align: center">Skolekoden finnes ikke</h1>
        <p>Du har tastet inn en kode som ikke eksisterer. Heldigvis kan du enkelt rette på det ved å taste inn på nytt nedenfor:</p>
        <v-ons-list>
          <v-ons-list-item>
            <div class="center">
              <v-ons-input placeholder="riktig skolekode" float
                v-model="skolekode"
              >
              </v-ons-input>
              
            </div>
          </v-ons-list-item>

          <v-ons-list-item>
            <v-ons-button style="margin: 6px 0" @click="updateSkolekode">Oppdater skolekode</v-ons-button>
          </v-ons-list-item>
        </v-ons-list>
      </div>
      <div v-else style="text-align: center">
        <h1>Skolekoden ble oppdatert</h1>
        <p>Det gikk bra! Nå kan du se film.</p>
        <router-link :to="{ name: 'library' }">
          <v-ons-button class="red_bg" style="margin-left: 12px;">
            SE FILM
          </v-ons-button>
        </router-link>
      </div>      
    </div>

    <v-ons-modal :visible="checkSkolekode" @click="modalVisible = false">
      <p style="text-align: center">
        Kontrollerer skolekode <v-ons-icon icon="fa-spinner" spin></v-ons-icon>
      </p>
    </v-ons-modal>

    <v-ons-modal :visible="checkSkolekode" @click="modalVisible = false">
      <p style="text-align: center">
        Riktig skolekode funnet. Oppdaterer bruker.<v-ons-icon icon="fa-spinner" spin></v-ons-icon>
      </p>
    </v-ons-modal>    

    <v-ons-toast :visible.sync="notValid" animation="fall">
      Kan ikke finne skolekode. Prøv igjen.
      <button @click="setValidTrue">ok</button>
    </v-ons-toast>

  </v-ons-page>
</template>

<script>
import MobileTopNav from '@/components/menus/MobileTopNav'
import { API, Auth } from 'aws-amplify'; // Hub
import { hasValidLicenseQuery } from '@/graphql/customQueries'

export default {
  data () {
    return {
      skolekode: undefined,
      checkSkolekode: false,
      updateUser: false,
      notValid: false,
      newCodeOk: false
    }
  },
  components: { MobileTopNav },
  methods: {
    setValidTrue () {
      this.notValid = false
    },
    // updateUserAttributes(user: CognitoUser | any, attributes: object): Promise<string>;
    async updateSkolekode () {
      this.valid = true
      // Sjekk om ny skolekode finnes
      const user = await Auth.currentAuthenticatedUser()
      const attributes = user.attributes

      const valid = await this.skolekodeValid (this.skolekode)

      if (valid) {
        console.log('valid ?', valid)
      } else {
        this.notValid = true
        return
      }

      // Oppdater skolekode
      attributes.zoneinfo = this.skolekode
      console.log('USER', attributes)
      let res
      try {
        res = await Auth.updateUserAttributes(user, attributes)
      } catch (err) {
        alert('Noe gikk galt', err)
        console.log('update err: ', err)
        return
      }
      this.newCodeOk = true
    
      console.log('res this.newCodeOk', this.newCodeOk)

      // Aktiver melding om at alt er greit samt knapp til library (Se film)
    },
    async skolekodeValid (code) {
        this.checkSkolekode = true
        let validLicense = false
        try {
          const { data } = await this.hasValidLicense (code)
          console.log('hasValidLicense data: ', data)
          validLicense = data.hasValidLicense ? true : false
        } catch (err) {
          console.log('hasValidLicense err ', err)
        }
        this.checkSkolekode = false

        if(validLicense) {
          return true
          // redirect to library
          // this.$router.replace({ name: 'library'})
        } else {
          // redirect to skolekodeside
          console.log('No valid license')
          this.validLicense = false
          return false
        }
    },
    async hasValidLicense (code) {
      let res
      try {
        res = await API.graphql({
          query: hasValidLicenseQuery,
          variables: { code: code },
          authMode: 'API_KEY'
        })
      } catch (err) {
        console.log('API err: ', err)
      }
      console.log('hasValidLicense res', res)
      return res
    }    
  }
}


</script>

<style lang="scss" scoped>
  .skolekode-melding {
    max-width: 600px;
    padding: 30px;
    padding-left: 20px;;
    padding-right: 2em;
    margin-left: auto;
    margin-right:auto;
  }
</style>