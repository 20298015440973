<template lang="html">
  <div><h2>Overlay</h2></div>
  <!--div>
    <h1 onclick="event.stopPropagation(); alert('K1')">{{video.title}}</h1>
    <h1 @click.prevent="seek">Seek</h1>
    <h1 onclick="event.stopPropagation(); document.getElementsByTagName('video')[0].currentTime = 400;">Seek 600</h1>
  </div-->
</template>

<script>
export default {
  props: ['player', 'video'],
  data () {
    return {
    }
  },
  mounted () {
    // this.player = document.getElementsByTagName("video")[0]
  },
  methods: {
    seek () {
      // alert("HELLO", this.player)
      // const player = document.getElementsByTagName('video')[0]
    // console.log('video player', this.player)
      this.player.currentTime = 240
    }
  }
}
</script>

<style lang="scss">

</style>
