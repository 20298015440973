<template lang="html">
  <v-ons-page>
    <div class="video-nav-links">

      <div class="video-link-list">

        <div class="video-links-wrapper" v-for="video in category.videos.items" :key="video.id"
          :to="{ name: 'videos', params: { categorySlug: category.slug, videoSlug: video.slug, chapter: 'kapittel-0:start' } }"
        >
        <template v-if="video && video.id">
          <VideoNavLinksLink :video="video" :category="category" />
        </template>
        </div>
        
      </div>

    </div>
  </v-ons-page>
</template>

<script>
// import { thumb, secondsToMMSS } from '@/utils'
import Store from '@/store'

import VideoNavLinksLink from './VideoNavLinksLink'

// <!--span class="time">Spilletid: {{formatTime(600)}}</span-->
export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  components: {
    VideoNavLinksLink
  },
  methods: {
    transition () {
      Store.commit('setChapterPlayerA')
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-link-list {
    display: block;
    @include mquery(large_mobile) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
      grid-gap: 6px;
    }
    @include mquery(desktop) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30%, 1fr)); // 85px
      grid-gap: 6px;
    }
    // // http://thenewcode.com/1137/MinMaxing-Understanding-vMin-and-vMax-in-CSS
    // @include mquery(desktop_large) {
    //   overflow-x: auto;
    //   white-space: nowrap;
    //   display: inline-grid;
    //   // overflow-x: scroll;
    //   grid-template-columns: repeat(4, minmax(30%, 1fr));
    //   grid-gap: 6px;
    // }
  }
  .video-nav-links {
    margin-top: 9px; // only mobile - use device width / standalone pluss check for touch
    .category-name {
      height: 44px;
    }
    .video-link-list {
      // padding-left: 1.2vw;
      // padding-right: 1.2vw;
    }
  }

</style>
