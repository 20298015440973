import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    credentials: null,
    transition: 'lift',
    allLoaded: false,
    loading: true,
    secret: undefined,
    hashedState: undefined,
    openIdState: undefined
  },
  mutations: {
    setUser (state, user) {
      state.user = user
    },
    setCredentials (state, credentials) {
      state.credentials = credentials
      // console.log('credentials.authenticated', credentials.authenticated)      
    },
    setChapterPlayerA (state) {
      state.chapterPlayerA = !state.chapterPlayerA
    },
    setTransition (state, transition) {
      state.transition = transition
    },
    setAllLoaded (state, value) {
      state.allLoaded = value
    },
    setLoading (state, value) {
      state.loading = value
    },
  },
  getters: {
    loading: state => {
      return state.loading
    },
    // authenticated: state => {
    //   return state.credentials.authenticated // If expired go to login
    // },
    user: (state) => {
      return !!state.user // If expired go to login
    },
  },
  actions: {
  },
  modules: {
  }
})
