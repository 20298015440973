<template lang="html">
  <v-ons-toolbar ref="navBar" fixed-style="true" id="top-nav" class="top-nav red_bg">

    <!--div class="left" v-if="showCatalog">
      <v-ons-back-button id="back-button"
        @click="backClick"
        />
    </div-->
    <!--v-ons-back-button @click.prevent="pageStack.splice(1)"></v-ons-back-button-->
    <!-- se https://onsen.io/v2/guide/vue/ , Event Handling -->

    <!--div class="left left-button" v-if="showHome" @click="go('home')">
      <v-ons-icon size="1.6em" style="padding-left: 6px;" icon="ion-home">
      </v-ons-icon>
    </div-->

    <div class="left left-button" v-if="showHome" @click="go('home')">
      <img src="@/assets/letter-logo-50px.png" style="padding-top: 8px;padding-left:4px;height: 28px; width: auto"/>
    </div>

    <!--div class="left left-button" v-if="!showCatalog && !showLogo" @click="go('library')">
      <v-ons-icon size="1.1em" style="padding-left: 6px;" icon="fa-th">
      </v-ons-icon>
    </div-->
    <div class="left left-button" v-if="!showCatalog && !showLogo">
      <router-link :to="{ name: 'library'}" tag="div">
        <v-ons-icon size="1.1em" style="padding-left: 6px;" icon="fa-th">
        </v-ons-icon>
      </router-link>
    </div>

    <BurgerMenu class="right burger"/>
    <!-- THE ABOVE NEEDS TO COME FIRST IN ORDER FOR RIGHT LEFT CLASSES TO WORK -->
    <!-- CATALOG TITLE -->

    <div v-if="showCatalog" class="center nav-title">
      <span>Filmkatalog</span>
    </div>

    <!-- CATEGORY LINK -->
    <div v-if="video" class="center nav-title">
      Flere
      <router-link :to="{name: 'category', params: {categorySlug: video.category.slug}}">
        {{video.category.name}}
      </router-link>
    </div>

    <!-- CATEGORY Name -->
    <div v-if="category && !video" class="center nav-title">
      {{category.name}}
    </div>

    <!-- LOGO -->
    <div class="toolbar-logo center">
      <img src="@/assets/fagfilm-logo-white-200.png" v-if="showLogo"/>
      <small v-if="!showCatalog && showLogo"></small>
    </div>

  </v-ons-toolbar>
</template>

<script>

import BurgerMenu from './BurgerMenu'

export default {
  props: ['category', 'video', 'showLogo', 'showCatalog', 'showHome', 'landscapeHide'],
  data () {
    return {
      modalVisible: false
    }
  },
  components: {
    BurgerMenu
  },
  mounted () {
    // this.$refs.navBar.visible = false
    // console.log('this.$refs', this.$refs.navBar.show())
    // this.$refs.navBar.setVisibility(false)
    if (this.landscapeHide) {
    // console.log('HIDE!!')
      const bar = document.querySelector('#top-nav')
      bar.hide()
    }
  //  this.$refs.navBar.setVisibility(false)
  },
  methods: {
    go (to) {
    // console.log('Going ', to)
      this.$router.push({ name: to })
    },
    showModal () {
      this.modalVisible = true
    },
    signOutConfig () {
      return ''
    },
    backClick () {
      // stop propagation and nav to katalog ?
    // console.log('back click', this.$router.app.$children[0].pageStack) // .$children.pageStack
    }
  }
}
</script>

<style lang="scss" scoped>
  // #top-nav {
  //   // display: none;
  // }
  .hide {
    visibility: hidden !important;
    // @media all and (orientation : portrait) {
    //   visibility:visible;
    // }
    // @media all and (orientation : landscape) {
    //   visibility: hidden;
    // }
  }
  .nav-title {
    a {
      color: white;
      text-decoration: none;
      border-bottom: 1px solid rgba(255,255,255,.6);
    }
    span {
      color: white;
    }
  }

  .left-button {
    padding-left: 5px !important;
  }
  .toolbar-logo {
    img {
      display: inline-block;
      height:18px !important;
      margin-bottom: -4px;
    }
  }

  .center-logo {
    position: absolute;
    width: 100%;
    display: grid;
    .toolbar-logo {
      margin-left: auto;
      margin-right: auto;
    }
  }
  .home {

  }
  #back-button {
    svg {
      fill: white !important;
    }
  }

  .burger {
    position: relative;
    bottom: 4px;
  }

</style>
