export const strings = {
  no_NB: {
    // 'An account with the given email already exists.': 'En bruker med samme navn finnes allerede',
    'Back to Sign In': 'Tilbake til innlogging',
    'Change': 'Endre',
    'Change Password': 'Endre passord',
    'Password *': 'Passord',
    'Verification code': 'Verifiseringskode',
    'Enter code': 'Legg inn kode',
    'Confirmation Code': 'Bekreftelseskode',
    'Enter your code': 'Legg inn din kode',
    'Confirm Sign up': 'Bekreft innmelding',
    'Lost your code?': 'Mistet din kode',
    'Resend Code': 'Send kode på nytt',
    'Confirm': 'Bekreft',
    'Confirm SMS Code': 'Bekreft sms-kode',
    'Confirm TOTP Code': 'Bekreft TOTP-kode',
    'Create account': 'Opprett konto',
    'Email Address *': 'Epostadresse *',
    'Enter your email address': 'Skriv inn din epostadresse',
    'Forgot your password?': 'Glemt ditt passord ?',
    'Less than two mfa types available': 'Mindre enn to mfa typer tilgjengelig',
    'New password': 'Nytt passord',
    'Enter your password': 'Tast inn ditt passord',
    'No account?': 'Ingen konto?',
    'Phone Number *': 'Telefonnummer',
    '(555) 555-1212': '+47 91 92 93 94',
    'qrcode': 'qrkode',
    'Reset password': 'Nullstill passord',
    'Reset your password': 'Nullstill ditt passord',
    'Select MFA Type': 'Velg MFA-type',
    'Verify': 'Bekreft',
    'Send Code': 'Send kode',
    'TOTP needs to be configured': 'TOTP trenger konfigurering',
    'Sign In': 'Logg inn',
    'Sign in to your account': 'Logg inn til din konto',
    'Sign in': 'Logg inn',
    'Sign in with Amazon': 'Logg inn med Amazon',
    'Sign in with Auth0': 'Logg inn med Auth0',
    'Sign in with AWS': 'Logg inn med AWS',
    'Sign in with Facebook': 'Logg inn med Facebook',
    'Sign in with Google': 'Logg inn med Google',
    'Sign Out': 'Logg ut',
    'Email': 'Epost',
    'Have an account?': 'Har du konto ?',
    'Create a new account': 'Opprett ny konto',
    'Password': 'Passord',
    'Create Account': 'Opprett konto',
    'Username': 'Brukernavn',
    'Success! Your MFA Type is now:': 'Det gikk bra! Din MFA-type er nå',
    'Scan then enter verification code': 'Skann, der etter legg inn verifiseringskode',
    'Enter Security Code:': 'Legg in sikkerhetskode',
    'TOTP Setup has failed': 'TOTP oppsett har feilet',
    'Verify Security Token': 'Bekreft sikkerhetmerke',
    'Setup TOTP successfully!': 'Oppsett TOTO gikk bra!',
    'Failed! Unable to configure MFA at this time': 'Feil! Kan ikke konfigurere MFA akkurat nå',
    'Username *': 'Brukernavn *',
    'Enter your username': 'Skriv inn ditt brukernavn',
    'Account recovery requires verified contact information': 'Konto-gjenoppretting krever verifisert kontaktinformasjon',
    'Phone Number': 'Telefonnummer',
    'Submit': 'Send inn',
    'Address': 'Adresse',
    'Enter your Address': 'Skriv inn din adresse',
    'Nickname': 'Kallenavn',
    'Enter your Nickname': 'Skriv inn ditt kallenavn',
    'Birthday': 'Fødselsdato',
    'Enter your Birthday': 'Legg inn din fødselsdato',
    'Picture URL': 'Bilde-url',
    'Enter your Picture URL': 'Tast inn din bilde-url',
    'Family Name': 'Etternavn',
    'Enter your Family Name': 'Skriv inn ditt etternavn',
    'Preferred Username': 'Foretrukket brukernavn',
    'Enter your Preferred Username': 'Skriv inn ditt foretrukne brukernavn',
    'Gender': 'Kjønn',
    'Enter your Gender': 'Velg kjønn',
    'Profile URL': 'Profil-url',
    'Enter your Profile URL': 'Tast inn din profil-url',
    'First Name': 'Fornavn',
    'Enter your First Name': 'Skriv inn ditt fornavn',
    'Time zone': 'Tidssone',
    'Enter your Time zone': 'Velg tidssone',
    'Locale': 'Språksone',
    'Enter your Locale': 'Velg språksone',
    'Updated At': 'Oppdatert den',
    'Enter the time the information was last updated': 'Legg inn tidspunkt for når informasjonen sist ble opppdatert.',
    'Middle Name': 'Mellomnavn',
    'Enter your Middle Name': 'Skriv inn ditt mellomnavn',
    'Website': 'Nettstedsadresse',
    'Enter your Website': 'Tast inn din nettstedsadresse',
    'Full Name': 'Fullt navn',
    'Enter your Full Name': 'Skriv inn ditt fulle navn'
  },
  en: {
    'An account with the given email already exists.': 'En bruker med samme navn finnes allerede'
  }
}