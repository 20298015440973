<template lang="html">

  <div id="videocontainer" :style="{width: width + 'px'}">

    <!-- <div id="invisible">
      <div class="overlay" id="overlay">
        <VideoOverlay :player="parentPlayer" :video="video"/>
      </div>
    </div> -->
  <div class="video-player">
    <vue-plyr ref="plyr" id="plyr-player">
      <video id='video-player'
        controls
        data-plyr-config='{
          "controls": [
            "play", "play-large", "progress",
            "current-time", "volume", "fullscreen",
          ],
          "blankVideo": "https://cdn.plyr.io/static/blank.mp4",
          
        }'
      >
      </video>
    </vue-plyr>
  </div>
    <!-- <div class="video-title-area" v-if="isPortrait || isIPad">
      <h4>{{video.title}}</h4>
    </div> -->

    <div class="demo-mode" v-if="!$store.getters.user">
      <span>Spilleren er i demo-modus. Du kan spille av 7 sekunder om gangen for å bli bedre kjent med Fagfilm.<br/>

        <router-link :to="{ name: 'Authenticator' }">
          <v-ons-button modifier="quiet" class="auth-button">
            Logg inn
          </v-ons-button>
        </router-link>

        </span><br/> for full tilgang, eller 

        <router-link :to="{ name: 'order' }">
          <v-ons-button modifier="quiet" class="auth-button">
            bestill fagfilm.
          </v-ons-button>
        </router-link>
    </div>

  </div>

</template>
<script>
// data-plyr-config=' {"debug": true, "title":"Test", "ads": { "enabled": false, "publisherId": "902676586493663" } } '
// data-plyr-config='{ "volume": 0.5, "resetOnEnd": true, "enabled": false }'
// https://css-tricks.com/NetMag/FluidWidthVideo/Article-FluidWidthVideo.php
import { thumb } from '@/utils.js'
import VideoOverlay from './PlayerBaseViewOverlay'
// import awsExports from '@/aws-exports-prod'

// function myControls () {
//   alert('Hello')
//   return {

//   }
// }

export default {
  props: ['video', 'width'], // LAG EGEN MOBILVERSJON
  name: 'VideoPlayer',
  data () {
    return {
      parentPlayer: null,
      isPortrait: true,
      isIpad: false,
      
    }
  },
  created () {
    this.isIPad = this.$ons.platform.isIPad()
  },
  mounted () {
    // this.parentPlayer = this.$refs.plyr.player
    // console.log('mounted - loading video')
    this.loadVideo()
    // this.initOverlay()
    // this.insertOverlay ()

    if (this.$root.isTouchScreenDevice) { // add: and not tablet!
      this.isPortrait = this.$ons.orientation.isPortrait()
      this.$ons.orientation.on('change', c => {
        this.isPortrait = this.$ons.orientation.isPortrait()
      })
    }
    console.log('user: ', this.$store.getters.user)

    if(!this.$store.getters.user) {
      setTimeout( () => {
        this.player.on('play', () => {
          console.log('play start')
          setTimeout( () => {
            this.player.pause ()
          }, 7*1000)
        });
      }, 500)
    }



  },
  beforeDestroy () {
    this.player.destroy()
  },
  computed: {
    player () {
      // this.parentPlayer = this.$refs.plyr.player // ooops: side-effets
      // return document.getElementById('video-player')
      // return this.parentPlayer
      const player = this.$refs.plyr.player 
      // player.config.fullscreen.enabled = false
      // console.log('player.config', player.config)
      return player
    },
    user () {
      return false;
    }
  },
  components: {
    VideoOverlay
  },
  watch: { // Gets triggered when setVideoUrl is run.
    '$route' (to, from) {
      if (from.params.videoSlug === to.params.videoSlug) { // CHapter changed !
        // const chapterNumber = to.params.chapter.split(':')[0].split('-')[1]
        // console.log('chapter change')
        // this.goToChapter(chapterNumber)
      } else { // Video changed !! - Bruke on before route ?
        this.deepLinked = false //
      // console.log('video change')
        this.loadVideo() // Se mounted
      }
    }
  },
  methods: {
    play () {
      this.player.play()
    },
    // initOverlay () {
    // // console.log('plyrVideoWrapper', this.plyrPlayer)
    //   const plyrVideoWrapper = this.player.elements.container.childNodes[1]
    //   const overlay = document.getElementById('overlay')
    //   plyrVideoWrapper.appendChild(overlay)
    //   // Make a reference to the overlay inside plyr-player
    //   this.overlay = this.player.elements.container.childNodes[1].querySelector('#overlay')
    // },
    insertOverlay () {
      const plyrVideoWrapper = this.player.elements.container.childNodes[1]
      plyrVideoWrapper.appendChild(this.overlay)
    },
    selectVideo (selectedVideo) {
      this.selectedVideo = selectedVideo
    },
    thumb (fileName, time, width) {
      return thumb(fileName, time, width)
    },
    url () {
      // const s3videoFolder = awsExports.aws_user_files_s3_bucket + '.s3-eu-west-1.amazonaws.com/public/'
      // const videoUrl = 'https://' + s3videoFolder + this.video.fileName

      const cdn = 'https://dmup9golsctl.cloudfront.net/'
      const videoUrl = cdn + this.video.fileName
      return videoUrl
    },
    loadVideo () { // Only used for master-detail view
    // console.log('LOADING VIDEO')
      this.player.source = {
        type: 'video',
        sources: [
          {
            src: this.url(this.video),
            type: 'video/mp4',
            size: this.$root.Constants.THUMBNAIL_WIDTH,
          }
        ],
        
        poster: thumb(this.video.fileName, this.video.posterTime, this.$root.Constants.THUMBNAIL_WIDTH)
      }
    },
    playDeepLinkedChapter () {
      const chapterNumber = this.$route.params.chapter.split(':')[0].split('-')[1]
      if (chapterNumber > 0) {
        const chapters = this.video.chapters.items
        const chapter = chapters[chapterNumber - 1]
        this.player.once('progress', () => {
          const playPromise = this.player.play()
          if (playPromise !== undefined) {
            playPromise.then((player) => {
              this.player.currentTime = chapter.start
            }).catch((error) => {
              this.player.currentTime = chapter.start
            // console.log(error)
              // Automatic playback failed.
              // Show a UI element to let the user manually start playback.
            })
          } else {
            try {
              this.player.play()
            } catch (err) {
            // console.log('could not play', err)
            }
            this.player.currentTime = chapter.start
          }
        })
        this.deepLinked = true // Promise not supported
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-player {
    --plyr-color-main: #1ac266;
  }
  .demo-mode {
    font-size: .6em;
    line-height: 1.1em;
    width: 120px;
    padding: 10px;
    position: absolute;
    top: 44px;
    background: rgba(0,0,0,.3)   
  }

  @media only screen and (min-width: 390px) {
    .demo-mode {
      font-size: .8em;
      line-height: 1.3em;
      width: 120px;
    }
  }

  .mobile-player {
    @include orientation(landscape) { // only mobile!!
      // height: 100vh;
      // width: 178vh; // 56.25 <-> 43.75 9/16 => 10=>
      // overflow: hidden;
      // margin: auto;
    }

  }
  video {
    display: inline-block;
    -webkit-transform-style: preserve-3d;
    @include orientation(landscape) {
      // height: 100vh !important;
    }
  }

  #invisible {
    display: none;
  }
  .overlay {
    // background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 300px;
    right: 0;
    bottom: 40px;
    pointer-events: all !important;
    overflow: hidden;
    z-index: 2147483647;
  }

  .video-title-area {
    height: $title-bar-height;
    background: #111;
    border-bottom: 1px solid #272727;
    h4 {
      line-height: 45px;
      margin:0px;
      color: white;
      text-align: center;
      width: 100%;
      vertical-align: center;
    }
  }
</style>
