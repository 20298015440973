<template lang="html">
  <v-ons-page>
    <MobileTopNav :showLogo="true" :showHome="true"/>
    <div class="logout-page">
      <h2>Du har blitt logget ut av Fagfilm og Feide</h2>
      <p>Men du kan fortsatt være innlogget på andre applikasjoner som bruker Dataporten</p>
      <img src="@/assets/feide.png" id="feide-logo"/>
    </div>
  </v-ons-page>
</template>

<script>
  import MobileTopNav from '@/components/menus/MobileTopNav'
  import { redirect_uri } from '@/constants'

  export default {
    components: {
      MobileTopNav
    },
    data () {
      return {
        test: redirect_uri
      }
    },
    created () {
      // console.log('test ', this.test)
    }
  }
</script>
<style lang="scss" scoped>

.logout-page {
    background: rgb(5, 26, 43);
    padding:20px;
    height: calc(100vh - 84px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      color: white;
      font-size: .9em;
    }
    img#feide-logo {
      width: 60px
    }
  }

  #call-link {
    display: inline-block;
    width: 115px;
    border-radius: 5px;
    margin-top: .7em;
    margin-left: -1px;
    color: white;
    background-color:rgb(7, 36, 59);// #CC0000;
    padding: 0.6em 0.9em;
    text-decoration: none;
    #phone {
      margin-right: 7px;
    }    
    a:hover {
      cursor: pointer;
      background-color:rgb(0, 153, 255) !important;
    }

  }
</style>