<template lang="html">
  <v-ons-page>
    <VideoChapterUI v-if="video && !loading" :video="video" :categories="categories"/>
  </v-ons-page>
</template>

<script>
// <!--v-ons-progress-bar indeterminate v-else></v-ons-progress-bar-->

import { loadContent } from '@/utils'
import VideoChapterUI from './VideoChapterUI'

export default {
  data () {
    return {
      isIPad: false,
      categories: [],
      loading: true
    }
  },
  async created () {
    this.isIPad = this.$ons.platform.isIPad()

    // LOAD CONTENT:
    this.categories = await loadContent() // START LOADING
    this.loading = false // LOADING FINNISHED
    console.log('Player: this.categories', this.categories)
  },
  components: {
    VideoChapterUI
  },
  watch: { // Gets triggered when setVideoUrl is run.
    '$route' (to, from) {
      if (from.params.videoSlug === to.params.videoSlug) { // CHapter changed !
      // console.log('chapter change')

        const chapterNumber = to.params.chapter.split(':')[0].split('-')[1]
      // console.log('chapterNumber ',chapterNumber )
        this.goToChapter(chapterNumber)
      }
    }
  },  

  methods: {
    // Move to PlayerBaseView or VideoChapterUI
    goToTime (t) {
      const player = document.getElementsByTagName('video')[0]
      player.play()
      player.currentTime = t
    },
    goToChapter (num) {
      this.goToTime(this.chapters[num - 1].start)
    },    
    orientation (e) {
      if (e.matches) {
      // console.log('orientation', e)
      } else {
      // console.log('orientation no match', e)
      }
    },
    makeSlug (title) {
      return title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').replace(/--/g, '-').toLowerCase()
    }
  },
  computed: {
    chapters () {
      return this.video.chapters.items
    },
    video () {
      if(!this.categories.length > 0) {
        return false
      }
      const catVideos = this.categories.map(cat => cat.videos.items)
      const videos = [].concat.apply([], catVideos)

      const video = videos.find(video => video.slug == this.$route.params.videoSlug)

      const category = this.categories.find(category => category.id === video.categoryId)

      video.category = { name: category.name, slug: category.slug }

      video.categorySlug = category.slug

      return video
    }
  }
}
</script>

<style lang="scss" scoped>
  .chapters {
    display: block;
    height: calc(100vh - 56.25vw);
    overflow-x: hidden;
    width: 100%;
  }
  .ipad-chapters {
    @media all and (min-aspect-ratio: 12/10) {
      height: calc(768px - (1024px * .5625)) !important;
    }
  }
  .player {
    background: purple;
  }
  .left-button {
    padding-left: 5px !important;
  }
</style>
