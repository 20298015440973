<template>
  <v-ons-page>
    <MobileTopNav :showLogo="true" :showHome="true"/>  
    <div class="info-page privacy-wrapper">
      <div class="privacy">
        <h1>Personvernerklæring</h1>
        <p>
          Fagfilm.no driftes av Pareto Film AS. Vi er behandlingsansvarlig og har ansvaret for at
          opplysningene som vi har om deg brukes i tråd med personvernlovgivningen.

          I denne personvernerklæringen gir vi deg informasjon om hvordan vi bruker og beskytter dine
          personopplysninger og hvilke rettigheter du har. Personopplysninger er alle opplysninger
          som kan knyttes til deg som enkeltperson.
        </p>
  
        <Collapse>
          <template v-slot:header>
            <h3>Hvilke opplysninger vi har om deg</h3>
          </template>
          <template v-slot:content>
            <div>
              <h4>Opplysninger som gis av deg</h4>
              <p>
                Når du melder deg på Fagfilm som bruker registrerer vi din epostadresse
                og hvilken skole/institusjon du tilhører (ved hjelp av skolekoden)
              </p>
              <h4>Opplysninger som samles inn om deg</h4>
              <p>
                Når du logger inn registerer vi <b>datoen for siste innlogging.</b>
                Er du kontaktperson hos lisensholder (skolen) har vi også fått ditt <b>fulle
                navn</b> og <b>jobb-epostadresse</b> når vi har kontaktet deg gjennom å ringe skolens hovednummer.
                Hvis du skal bruke Fagfilm kan innsamling av disse opplysningene ikke velges bort.</p>
            </div>
          </template>
        </Collapse>

        <Collapse>
          <template v-slot:header>
            <h3>Hva vi bruker opplysningene til (formål)</h3>
          </template>
          <template v-slot:content>
            <div style="text-align: left">
            <ol>
              <li><b>Sende deg en epost for å verifisere at din epostadresse er riktig.</b> For dette formålet bruker vi din epostadresse.</li>
              <li><b>For å verifisere at du kan bruke skolens/institusjonens lisens.</b> For dette formålet bruker vi skolekoden som er knyttet til din epostadresse når du logger inn.</li>
              <li><b>For nullstilling av passord.</b> For dette formålet benyttes din epostadresse.</li>
              <li><b>For produktinformasjon og kundeoppfølging.</b> Dersom du er kontaktperson hos lisensholder benyttes din epostadresse til å sende deg informasjon om bruk av tjenesten og om nytt tilgjengelig innhold samt endring av innhold.</li>
              <li><b>For sletting av bruker.</b> For dette formålet bruker vi datoen for siste innlogging.</li>
              <li><b>Vi deler ikke personopplysninger med andre</b></li>
            </ol>
            </div>
          </template>
        </Collapse>

        <Collapse>
          <template v-slot:header>
            <h3>Hvor lenge lagrer vi opplysningene</h3>
          </template>
          <template v-slot:content>
            <p>
              Vi lagrer opplysningene kun så lenge det er nødvendig. Dette innebærer at vi sletter automatisk:
              Din brukerkonto og dato for siste innlogging innen 6 måneder etter siste innlogging.
            </p>
          </template>
        </Collapse>

        <Collapse>
          <template v-slot:header>
            <h3>Dine personvernrettigheter</h3>
          </template>
          <template v-slot:content>
            <p>Du har en rekke personvernrettigheter. Disse kan du benytte deg av ved å ta kontakt med oss. Dine rettigheter omfatter:</p>
            <ol style="text-align: left">
              <li><b>Informasjon.</b> Ytterligere informasjon om hvordan vi behandler opplysninger om deg.</li>
              <li><b>innsyn.</b>  Kopi av opplysninger vi har om deg.</li>
              <li><b>Korrigering.</b>  Rette og supplere opplysninger om deg</li>
              <li><b>Sletting.</b>  Be om sletting av opplysninger som vi ikke lenger har grunnlag for å oppbevare.</li>
              <li><b>Begrensning.</b>  Be om at vi begrenser bruken av dine opplysninger.</li>
              <li>Dataportabilitet. Be om at dine opplysninger overføres til deg eller til en annen virksomhet i et strukturert, alminnelig anvendt og maskinlesbart format.</li>
              <li>Be om å motsette deg vår bruk av dine opplysninger for direkte markedsføring, inkludert profilering.
                Du kan også motsette deg å være gjenstand for individuelle avgjørelser av rettslig karakter som utelukkende er automatisert.</li>
            </ol>
            <p>
              Innsigelse. Be om å motsette deg vår bruk av dine opplysninger for direkte markedsføring, inkludert pr
            </p>
            <p>Hvis du mener at vi behandler personopplysninger urettmessig, har du rett til å klage til Datatilsynet.
              Vi håper at du først tar kontakt med oss, slik at vi kan vurdere dine innsigelser og oppklare eventuelle misforståelser.</p>
          </template>
        </Collapse>

        <Collapse>
          <template v-slot:header>
            <h3>Databehandlere og datasikkerhet</h3>
          </template>
          <template v-slot:content>
            <p>
              Fagfilm benytter kun databehandlere (skytjenester) som lagrer data innenfor EU/EØS.
              All data som ligger i ro er kryptert og våre hjemmesider benytter kryptert
              informasjonsoverføring via https. Vi benytter to-faktor-autentisering ved innlogging til databehandlere.
            </p>
          </template>
        </Collapse>
      </div>
    </div>
  </v-ons-page>
</template>


<script>
import MobileTopNav from '@/components/menus/MobileTopNav'
import Collapse from '@/components/Collapse'
export default {
  components: {
    MobileTopNav,
    Collapse
  }
}
</script>

<style lang="scss" scoped>
  .privacy-wrapper {
      padding-left: 7%;
      padding-right: 7%
  }
  .privacy {
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 1.6em;
    p {
      text-align: left;
    }
    .collapse {
      width: 100%;
    .collapse-content {
      p {
        text-align: left;
      }
    }
    }
  }
</style>