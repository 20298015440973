<template lang="html">
  <v-ons-toolbar
    ref="navBar"
    fixed-style="true"
    inline
    class="red_bg"
    id="player-top-nav"
  >
    <div :class="{'show-nav-links': showNavLinks}" id="toolbar-layout">
      <div id="library-link"  @click="go('library')">
        <v-ons-icon size="1.1em" style="padding-left: 6px;" icon="fa-th"></v-ons-icon>
      </div>

      <div v-if="category" style="justify-self: center" id="category-link">
        <span>Flere </span>
        <router-link :to="{name: 'category', params: {categorySlug: category.slug}}" id="cat-link">
          <span>{{category.name}}</span>
        </router-link>
      </div>

      <div id="burger-menu">
        <BurgerMenu />
      </div>
    </div>
  </v-ons-toolbar>
</template>

<script>

import BurgerMenu from '@/components/menus/BurgerMenu'
export default {
  props: ['category', 'isTransparant'],
  data () {
    return {
      isPortrait: true,
      showNavLinks: false
    }
  },
  methods: {
    go (to) {
      this.$router.push({ name: to })
    }
  },
  mounted () {
    // if (this.$root.isTouchScreenDevice) { // add: and not tablet!
    //   this.isPortrait = this.$ons.orientation.isPortrait()
    //   this.$ons.orientation.on('change', c => {
    //     this.isPortrait = this.$ons.orientation.isPortrait()
    //   })
    // }
    const layoutObserver = new ResizeObserver((entries) => {
      const chapterEl = entries[0]
      this.showNavLinks = chapterEl.contentRect.width > 256 ? true : false
    })
    layoutObserver.observe(document.querySelector("#toolbar-layout"))
  },
  components: {
    BurgerMenu
  }
}
</script>

<style lang="scss" scoped>
  #library-link {
    visibility: hidden;
    width: 0px;
    margin-left: 5%
  }
  #cat-link {
    color: white;
    a {
      :visited {
        color: white;
      };
      :hover {
        font-weight: bolder;
      };
    }
  }
  #burger-menu {
    width: 0px;
    visibility: hidden;
    justify-self: right;margin-right: 5%
  }

  #toolbar-layout {
    width: 100;
    height: 44px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    align-items: center;
  }
  #player-top-nav {
    display: inline-block;
  }
  /* media queries */
  .show-nav-links {
    @media only screen and (min-width: 256px) {
      #library-link {visibility: visible; width: auto}
      #burger-menu {visibility: visible; width: auto}
    }
  }


  @media only screen and (min-aspect-ratio: 145/100) {
    #player-top-nav {
      display: none;
    }
  }

  @media only screen and (min-aspect-ratio: 170/100) {
    #player-top-nav {
      display: block;
    }
  }
</style>
