<template>
  <v-ons-page style="overflow: none; margin-bottom: -36px;">
    <MobileTopNav :showLogo="true"/>
    <FrontPage/>
  </v-ons-page>
</template>

<script>
// @ is an alias to /src
import MobileTopNav from '@/components/menus/MobileTopNav'
import FrontPage from '@/components/landing_page/FrontPage'

export default {
  name: 'Home',
  components: {
    FrontPage,
    MobileTopNav
  }
}
</script>

<style lang="scss" scoped>

</style>
